import { createContext, useContext, useState, useEffect } from "react";
import { signInWithEmailAndPassword, signOut, onAuthStateChanged, sendPasswordResetEmail, confirmPasswordReset} from 'firebase/auth';
import {auth} from './firebase';
import axios from 'axios';
import { Storage } from "@ionic/storage";

const UserContext = createContext()

export const AuthContextProvider = ({children}) => {
    const [user, setUser] = useState({})
    const [selectedLanguageCode, setSelectedLanguageCode] = useState('en');
    const [nativeLanguageCode, setNativeLanguageCode] = useState('en');

    const storage = new Storage();
    storage.create();

    // const createUser = (email, password) => {
    //     return createUserWithEmailAndPassword(auth, email, password)
    // };
    const createUser = async (email, password, inviteCode) => {
        try {
            const response = await axios.post(process.env.REACT_APP_API_CREATE_USER, { email, password, inviteCode });
            return response.data;
        } catch (error) {
            console.error('Error creating user:', error);
            throw error;
        }
    };

    const logout = () => {
        return signOut(auth)
    };
    const login = (email, password) => {
        return signInWithEmailAndPassword(auth, email, password)
    };
    const resetPassword = (email) => {
        return sendPasswordResetEmail(auth, email);
    };
    const confirmPwReset = (newPassword, oobCode) => {
        return confirmPasswordReset(auth, oobCode, newPassword)
    };

    useEffect(()=> {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            console.log(currentUser)
            setUser(currentUser)
        })
        return () => {
            unsubscribe();
        }
    }, [])

    useEffect(() => {
        loadInitialSettings();
    }, []);

    const loadInitialSettings = async () => {
        const [selectedCode, nativeCode] = await Promise.all([
            storage.get('selectedLanguageCode'),
            storage.get('nativeLanguageCode')
        ]);
        if (!selectedCode) {
            await storage.set('selectedLanguageCode', 'en');
            setSelectedLanguageCode('en');
        } else {
            setSelectedLanguageCode(selectedCode);
        }
        if (!nativeCode) {
            await storage.set('nativeLanguageCode', 'en');
            setNativeLanguageCode('en');
        } else {
            setNativeLanguageCode(nativeCode);
        }
    };

    const updateLanguageSetting = async (key, value) => {
        await storage.set(key, value);
        if (key === 'selectedLanguageCode') {
            setSelectedLanguageCode(value);
            await storage.set('selectedLanguageCode', value);
        } else if (key === 'nativeLanguageCode') {
            setNativeLanguageCode(value);
            await storage.set('nativeLanguageCode', value);
        }
    };



    return (
        <UserContext.Provider value={{
                    createUser,
                    user,
                    logout,
                    login,
                    resetPassword,
                    confirmPwReset,
                    selectedLanguageCode,
                    nativeLanguageCode,
                    updateLanguageSetting}}>
            {children}
        </UserContext.Provider>
    )
}

export const UserAuth = () => {
    return useContext(UserContext);
}