import React, { useState, useEffect } from "react"
import {
  IonGrid,
  IonRow,
  IonCol,
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonSearchbar,
  IonButtons,
  IonMenuButton,
} from "@ionic/react"
import favoriteVideosService from "../utils/FavoriteVideosService"
import { useHistory } from "react-router-dom"

import { useMediaQuery } from "react-responsive"
import VideoCardCompact from "../components/VideoCard/VideoCardCompact"
import VideoCardGrid from "../components/VideoCard/VideoCardGrid"

interface FavoriteVideo {
  id: string
  title: string
  channelTitle: string
  description: string
  thumbnail: string
  channelId: string
  lengthText: string;
  publishDate: string;
  viewCount: string;
}

const FavoritesPage: React.FC = () => {
  //Determine size of device
  const isTabletOrDesktop = useMediaQuery({ query: "(min-width: 768px)" })

  //const [favorites, setFavorites] = useState([]);
  const [favorites, setFavorites] = useState<FavoriteVideo[]>([])
  const [searchTerm, setSearchTerm] = useState("")
  const history = useHistory()

  useEffect(() => {
    (async () => {
      const favs = await favoriteVideosService.getFavorites()
      setFavorites(favs)
    })()
  }, [])

  useEffect(() => {
    return history.listen(() => {
      // listen for changes in history
      (async () => {
        const favs = await favoriteVideosService.getFavorites()
        setFavorites(favs)
      })()
    })
  }, [history])

  const handleSearch = (e: any) => {
    setSearchTerm(e.target.value)
  }

  const filteredFavorites = favorites.filter((video: any) =>
    video.title.toLowerCase().includes(searchTerm.toLowerCase())
  )

  const adjustedFavorites = filteredFavorites.map((video) => ({
    id: { videoId: video.id },
    channelId: video.channelId,
    channelTitle: video.channelTitle,
    lengthText : video.lengthText || "",
    publishDate : video.publishDate || "",
    viewCount : video.viewCount || "",
    snippet: {
      title: video.title,
      channelTitle: video.channelTitle,
      description: video.description,
      thumbnails: {
        medium: {
          url: video.thumbnail,
        },
      },
    },
  }))

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
              <IonMenuButton color="primary" />
          </IonButtons>
          <IonTitle>Favorites</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className="compact-list-padding">
        <IonSearchbar
          className="custom-searchbar-local"
          value={searchTerm}
          onIonChange={handleSearch}
          debounce={500}
          enterkeyhint="search"
          placeholder="Search Favorites"
        ></IonSearchbar>
        {/*<Videos videos={filteredFavorites} /> */}
        {/* <Videos videos={adjustedFavorites} /> */}
        <IonGrid>
          <IonRow>
            {adjustedFavorites.map((video) => (
              <IonCol
                style={{ padding: 0 }}
                size="12"
                size-md="4"
                size-lg="2.3"
              >
                {isTabletOrDesktop ? (
                  <VideoCardGrid video={video} key={video.id.videoId} />
                ) : (
                  <VideoCardCompact video={video} key={video.id.videoId} />
                )}

                {/*<VideoCardCompact video={video} key={video.id.videoId} /> */}
              </IonCol>
            ))}
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  )
}

export default FavoritesPage
