/*
JMR
*/
import React, { useState, useEffect } from "react"
import {
  IonImg,
  IonRouterLink,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
} from "@ionic/react"
import HeartComponent from "../HeartComponent"
import favoriteVideosService from "../../utils/FavoriteVideosService"
import "../../App.scss"
import "./VideoCard.scss"
import { useHistory, useLocation } from "react-router-dom" //UF
//const VideoCard = ({ video: {id: { videoId }, snippet} }) => {

interface ThumbnailObj {
  url: string
}

interface VideoProps {
  id: {
    videoId?: string
  }
  snippet?: {
    title?: string
    channelId?: string
    channelTitle?: string
    thumbnails?: {
      medium?: {
        url?: string
      }
    }
    description?: string
  }
  title?: string
  channelTitle?: string
  description?: string
  thumbnail?: string | ThumbnailObj[]
  videoId?: string
  viewCount?: string
  publishedText?: string
  publishedTimeText?: string
  lengthText?: string
  channelThumbnail?: string
  channelId?: string
}

//const VideoCardMobile = ({ video: { id: { videoId }, snippet } }) => {
const VideoCardMobile = ({ video }: { video: VideoProps }) => {
  //console.log("[VideoCardMobile]: ");
  // For favorites, use the top level properties, else use the nested properties
  const videoId = video.id?.videoId || video.id || video.videoId
  const title = video.snippet?.title || video.title
  const channelId = video.snippet?.channelId || video.channelId
  const channelTitle = video.snippet?.channelTitle || video.channelTitle
  const description = video.snippet?.description || video.description
  //const thumbnailUrl = video.snippet?.thumbnails?.medium?.url || video.thumbnail || video.thumbnail?[0].url;
  const thumbnailUrl =
    video.snippet?.thumbnails?.medium?.url ||
    (typeof video.thumbnail === "string"
      ? video.thumbnail
      : Array.isArray(video.thumbnail) && video.thumbnail.length > 0
      ? video.thumbnail[video.thumbnail.length - 1]?.url
      : undefined)

  const viewCount = video.viewCount
  const vidPublished = video.publishedTimeText
  const vidLength = video.lengthText
  const channelThumb = video.channelThumbnail

  const [isFavorite, setIsFavorite] = useState(false)

  //UF
  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    // Checking if this video is a favorite on component load
    ;(async () => {
      const favorites = await favoriteVideosService.getFavorites()
      setIsFavorite(!!favorites.find((video) => video.id === videoId))
      //console.log("[UPDATING FAV] "+ title)
    })()
  }, [videoId, history, location])

  const handleFavoriteClick = async (event: React.MouseEvent) => {
    event.preventDefault()
    try {
      if (isFavorite) {
        await favoriteVideosService.removeFromFavorites(videoId)
        setIsFavorite(false)
      } else {
        //await favoriteVideosService.addToFavorites({id: { videoId }, video});
        await favoriteVideosService.addToFavorites(video)
        setIsFavorite(true)
      }

      // Updating the favorite status after user interaction
      //setIsFavorite(!isFavorite);
    } catch (error) {
      console.error(error)
    }
  }

  // Convert viewCount to a number and format it to include commas
  const formattedViewCount = Number(viewCount).toLocaleString()

  return (

      <IonCard class="vcard">
        <div className="vcard-content vcardMobile">
          {/*<div style={{paddingLeft:'0', borderRadius:'13px', overflow:'hidden'}}>
                    <IonImg src={thumbnailUrl} alt={title} />
                </div> */}
          <div className="vcardMobile__thumbnailContainer" onClick={() => history.push(`/video/${videoId}`)}>
            <IonImg src={thumbnailUrl} alt={title} />
            {/* Overlay vidLength on the bottom right of the thumbnail */}
            <div>{vidLength}</div>
          </div>

          <IonCardHeader class="vcard-header">
            <IonCardTitle className="vcard-title" onClick={() => history.push(`/video/${videoId}`)}>
              {(title || "").length > 60
                ? (title || "").substr(0, 60) + "..."
                : title || "[...]"}
            </IonCardTitle>
            <IonCardContent className="vstats">
              {formattedViewCount} views · {vidPublished}
            </IonCardContent>

            <IonRouterLink routerLink={`/channel/${channelId}`}>
              <IonCardSubtitle className="vsubtitle">
                {channelTitle}
              </IonCardSubtitle>
            </IonRouterLink>
          </IonCardHeader>

          <IonCardContent class="vcard-content" onClick={() => history.push(`/video/${videoId}`)}>
            {(description || "").length > 70
              ? (description || "").substr(0, 70) + "..."
              : description || "[...]"}
          </IonCardContent>

          <HeartComponent
            className="vheart-icon"
            solid={isFavorite}
            onClick={handleFavoriteClick}
            size="large"
          />
        </div>
      </IonCard>

  )
}

export default VideoCardMobile
