import React from "react"
import {
  IonFab,
  IonFabButton,
  IonFabList,
  IonButton,
  IonRow,
  IonCol,
  IonIcon,
} from "@ionic/react"
import {
  speedometerOutline,
  playOutline,
  pauseOutline,
  readerOutline,
  returnUpBackOutline,
  returnUpForwardOutline,
  informationCircleOutline,
} from "ionicons/icons"
import "./components.scss"

type PlayerControlsProps = {
  playing: boolean,
  handleSetPlaybackRate: (rate: number) => void,
  handlePlayPause: () => void,
  handleBackFive: () => void,
  handleForwardFive: () => void,
  subtitlesEnabled: boolean,
  subtitlesOrigEnabled: boolean,
  toggleOverlay: (key: string) => void,

}

const PlayerControls: React.FC<PlayerControlsProps> = ({
  playing,
  handleSetPlaybackRate,
  handlePlayPause,
  handleBackFive,
  handleForwardFive,
  subtitlesEnabled,
  toggleOverlay,
  subtitlesOrigEnabled,

}) => {
  const [activeOverlay, setActiveOverlay] = React.useState<string | null>(null)
  const [selectedSpeed, setSelectedSpeed] = React.useState<number>(1) // Default to 100%.

  const handleToggleOverlay = (key: string) => {
    if (activeOverlay === key) {
      // If the button with the same key is pressed again, set the activeOverlay to null
      setActiveOverlay(null)
    } else {
      setActiveOverlay(key)
    }
    //setActiveOverlay(key); //keep track of overlay state
    toggleOverlay(key)
  }

  const handleSpeedChange = (rate: number) => {
    setSelectedSpeed(rate)
    handleSetPlaybackRate(rate)
  }

  return (
    <IonRow className="controls">
      <IonCol className="controls__leftContent">
        <div className="controls__fabWrapper">
          <IonFab className="controls__fab">
            <IonFabButton color="medium" size="small" style={selectedSpeed !== 1 ? { borderRadius:'17px', border: '2px solid red' } : {}}>
              {/* <IonIcon icon={speedometerOutline}></IonIcon> */}
              {selectedSpeed === 1 ? (
                <IonIcon icon={speedometerOutline} />
              ) : (
                `${selectedSpeed * 100}%`
              )}
            </IonFabButton>
            <IonFabList side="top">
              <IonFabButton
                color={selectedSpeed === 0.5 ? "danger" : "warning"}
                onClick={() => handleSpeedChange(0.5)}
              >
                50%
              </IonFabButton>
              <IonFabButton
                color={selectedSpeed === 0.65 ? "danger" : "warning"}
                onClick={() => handleSpeedChange(0.65)}
              >
                65%
              </IonFabButton>
              <IonFabButton
                color={selectedSpeed === 0.75 ? "danger" : "warning"}
                onClick={() => handleSpeedChange(0.75)}
              >
                75%
              </IonFabButton>
              <IonFabButton
                color={selectedSpeed === 1 ? "danger" : "warning"}
                onClick={() => handleSpeedChange(1)}
              >
                <strong>100%</strong>
              </IonFabButton>
              <IonFabButton
                color={selectedSpeed === 1.25 ? "danger" : "warning"}
                onClick={() => handleSpeedChange(1.25)}
              >
                125%
              </IonFabButton>
              <IonFabButton
                color={selectedSpeed === 1.5 ? "danger" : "warning"}
                onClick={() => handleSpeedChange(1.5)}
                style={{ zIndex: 99999 }}
              >
                150%
              </IonFabButton>
              <IonFabButton
                color={selectedSpeed === 1.75 ? "danger" : "warning"}
                onClick={() => handleSpeedChange(1.75)}
                style={{ zIndex: 99999 }}
              >
                175%
              </IonFabButton>
            </IonFabList>
          </IonFab>
        </div>

        <IonButton
          color="primary"
          onClick={() => handleToggleOverlay("subtitlesOrigEnabled")}
          className={
            activeOverlay === "subtitlesOrigEnabled" ? "active-button" : ""
          }
        >
          <IonIcon icon={readerOutline}></IonIcon>
        </IonButton>

        <IonButton
          color="warning"
          onClick={() => handleToggleOverlay("subtitlesEnabled")}
          className={
            activeOverlay === "subtitlesEnabled" ? "active-button" : ""
          }
        >
          <IonIcon icon={readerOutline}></IonIcon>
        </IonButton>
      </IonCol>

      <IonCol className="controls__mainContent">
        <IonButton
          className="playback-button"
          color="primary"
          onClick={handleBackFive}
          fill="solid"
        >
          <IonIcon icon={returnUpBackOutline}></IonIcon>
        </IonButton>

        <IonButton
          className="playback-button"
          color="primary"
          onClick={handlePlayPause}
          fill="solid"
        >
          <IonIcon icon={playing ? pauseOutline : playOutline}></IonIcon>
        </IonButton>

        <IonButton
          className="playback-button"
          color="primary"
          onClick={handleForwardFive}
          fill="solid"
        >
          <IonIcon icon={returnUpForwardOutline}></IonIcon>
        </IonButton>
      </IonCol>
    </IonRow>
  )
}
export default PlayerControls
