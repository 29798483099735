import React, { createContext, useContext, useState } from 'react';


const SearchContext = createContext();

export const SearchProvider = ({ children }) => {
    const [searchResults, setSearchResults] = useState([]);
    const [searchResultsCh, setSearchResultsCh] = useState([]);
    const [lastChannel, setLastChannel] = useState({});
    const [lastClickedIndex, setLastClickedIndex] = useState(-1);
    const [searchResultsHome, setSearchResultsHome] = useState([]);
    const [lastHomeCheck, setLastHomeCheck] = useState(null);


    const updateSearchResults = (results) => {
        setSearchResults(results);
    };

    const updateSearchResultsCh = (results) => {
        setSearchResultsCh(results);
    };

    const updateLastClickedIndex = (index) => {
        console.log("CLICKED ON INDEX: "+ index);
        setLastClickedIndex(index);
    };

    const updateLastChannel = (ch) => {
        setLastChannel(ch);
    };

    const updateSearchResultsHome = (results) => {
        setSearchResultsHome(results);
    };

    const updateLastHomeCheck = (lastRun) => {
        setLastHomeCheck(lastRun);
    };
    
    return (
        <SearchContext.Provider value={{ searchResults, updateSearchResults, searchResultsCh, updateSearchResultsCh, lastClickedIndex, updateLastClickedIndex, lastChannel,updateLastChannel,searchResultsHome, updateSearchResultsHome,lastHomeCheck, updateLastHomeCheck }}>
            {children}
        </SearchContext.Provider>
    );
};

export const useSearch = () => useContext(SearchContext);
