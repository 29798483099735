import React, { useState, useRef } from "react"
import ReactPlayer from "react-player"
import { IonButton, IonInput, IonItem, IonLabel, IonPage } from "@ionic/react"

const PlayVideoPage: React.FC = () => {
  const [url, setUrl] = useState<string>("") // state for the video URL
  const [playing, setPlaying] = useState<boolean>(true) // state for the playing status of the video
  const playerRef = useRef<ReactPlayer>(null) // ref for the react-player component

  const handlePlayPause = () => {
    setPlaying(!playing)
  }

  const handleStop = () => {
    setPlaying(false)
    setUrl("")
  }

  const handleLoad = () => {
    setPlaying(true)
  }

  return (
    <IonPage>
      <IonItem>
        <IonLabel position="floating">Video URL</IonLabel>
        <IonInput
          type="text"
          value={url}
          onIonChange={(e) => setUrl(e.detail.value!)}
        ></IonInput>
      </IonItem>

      <div className="player-wrapper">
        <ReactPlayer
          ref={playerRef}
          className="react-player"
          width="100%"
          height="100%"
          url={url}
          playing={playing}
          controls={true}
          onReady={() => console.log("onReady")}
          onStart={() => console.log("onStart")}
          onPlay={() => console.log("onPlay")}
          onPause={() => console.log("onPause")}
          onBuffer={() => console.log("onBuffer")}
          onEnded={() => console.log("onEnded")}
          onError={(e) => console.log("onError", e)}
          onDuration={(duration) => console.log("onDuration", duration)}
        />
      </div>

      <div className="controls">
        <IonButton onClick={handleStop}>Stop</IonButton>
        <IonButton onClick={handlePlayPause}>
          {playing ? "Pause" : "Play"}
        </IonButton>
        <IonButton onClick={handleLoad}>Load</IonButton>
      </div>
    </IonPage>
  )
}

export default PlayVideoPage
