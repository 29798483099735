import React, { useState } from 'react';
import {
    IonButton,
    IonIcon,
    IonToast,
    IonInput,
    IonAlert,
    IonFab,
    IonFabButton,
    IonFabList
} from "@ionic/react"
import {
    createOutline,
    trashOutline,
    copyOutline,
    openOutline,
    ellipsisHorizontal,
} from "ionicons/icons"
import { Clipboard } from "@capacitor/clipboard"
import "./TodoItem.scss"
import { Browser } from '@capacitor/browser';
import { UserAuth } from '../../auth'


function TodoItem({ task, deleteTask, toggleCompleted, updateTaskText }) {
    const [isEditing, setIsEditing] = useState(false);
    const [editText, setEditText] = useState(task.text);
    const [showToast, setShowToast] = useState(false)
    const [toastMessage, setToastMessage] = useState("")
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [toBeDeletedId, setToBeDeletedId] = useState(null);
    //get context values
    const {selectedLanguageCode, nativeLanguageCode} = UserAuth();

    function handleChange() {
        toggleCompleted(task.id);
    }

    function handleEdit() {
        setIsEditing(true);
    }

    function handleSave() {
        updateTaskText(task.id, editText);
        setTimeout(() => setIsEditing(false), 100);
    }

    function handleTextChange(e) {
        setEditText(e.target.value);
    }

    const copyTileToClipboard = async (text, event) => {
        event.stopPropagation();
        if (text.trim()) {
          await Clipboard.write({
            string: text.trim(),
          });
          setToastMessage("Text copied to clipboard.");
          setShowToast(true);
        }
    }

    //Open Google Translate
    //Assumptions: the user is writing the tasks in their target language and 
    //going out to the translator with minor tweaks
  const openGoogleTranslate = async (text, event) => {
    event.stopPropagation();
    const encodedText = encodeURIComponent(text);

    const url = `https://translate.google.com/?sl=${selectedLanguageCode}&tl=${nativeLanguageCode}&text=${encodedText}&op=translate`;
    await Browser.open({ url });
  };


    return (
        <div className="todo-item">
            <IonToast
                isOpen={showToast}
                onDidDismiss={() => setShowToast(false)}
                message={toastMessage}
                duration={3000}
                position="middle"
            />
            <IonAlert
                isOpen={showDeleteConfirm}
                onDidDismiss={() => setShowDeleteConfirm(false)}
                header={'Confirm Delete'}
                message={'Are you sure you want to delete this task?'}
                buttons={[
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            setShowDeleteConfirm(false);
                        }
                    },
                    {
                        text: 'Yes, Delete',
                        handler: () => {
                            deleteTask(toBeDeletedId);
                        }
                    }
                ]}
            />

            <input 
                type="checkbox"
                checked={task.completed}
                onChange={handleChange}
            />
            <div style={{ flex: 1, marginLeft: '8px' }}>
                {isEditing ? (
                    <IonInput
                        type="text"
                        value={editText}
                        onIonChange={handleTextChange}
                        onBlur={handleSave}
                        onKeyDown={e => { if (e.key === 'Enter') handleSave() }}
                        autofocus
                        style={{ backgroundColor: isEditing ? 'grey' : '', fontSize: "1.2rem" }}
                        maxlength={128}
                    />                
                ) : (
                    <p className={`todo-text ${task.completed ? 'completed' : ''}`} onDoubleClick={handleEdit} style={{ fontSize: "1.2rem"}}>
                        {task.text}
                    </p>
                )}
            </div>
                
            <IonIcon
                icon={trashOutline}
                onClick={() => { setToBeDeletedId(task.id); setShowDeleteConfirm(true); }}
                style={{
                    cursor: "pointer",
                    fontSize: "1.3rem",
                    color: "red",
                    marginRight: "8px",
                }}
            />

            <IonIcon
                icon={createOutline}
                onClick={isEditing ? handleSave : handleEdit}
                style={{ marginRight: "8px", cursor: "pointer", fontSize: "1.3rem", color: "lightgrey" }}
            />

            <IonIcon
                icon={copyOutline}
                onClick={(e) => copyTileToClipboard(editText, e)}
                style={{ marginRight: "5px", cursor: "pointer", fontSize: "1.3rem", color: "lightgrey" }}
            />

            <IonIcon
                icon={openOutline}
                onClick={(e) => openGoogleTranslate(editText, e)}
                style={{ marginRight: "0px", cursor: "pointer", fontSize: "1.3rem", color: "lightgrey" }}
            />

        </div>
    );
}

export default TodoItem;
