import React, { useState } from 'react';
import { IonContent, IonPage, IonInput, IonButton, IonToast } from '@ionic/react';
import { useParams } from 'react-router';
import { UserAuth } from '../auth'
import { useHistory, useLocation } from 'react-router-dom';
import "./authPage.scss"

const ResetPasswordPage = () => {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    
    //get the query parameters
    const history = useHistory()
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const oobcode = queryParams.get('oobCode');
    console.log("Using reset code: ", oobcode)
  
    const { confirmPwReset} = UserAuth();

    const handleResetPassword = async () => {
        if (newPassword !== confirmPassword) {
            setToastMessage("Passwords don't match.");
            setShowToast(true);
            return;
        }

        try {
            await confirmPwReset(newPassword, oobcode);
            setToastMessage('Password has been reset successfully.');
            history.push(`/login`)
        } catch (error) {
            setToastMessage('Failed to reset password.');
            console.log(error)
        } finally {
            setShowToast(true);
        }
    };

    return (
        <IonPage>
            <IonContent className="ion-padding">
            <div className="auth-text">Space Subs</div>
                <IonInput
                    placeholder="New Password"
                    type="password"
                    onIonChange={(e) => setNewPassword(e.detail.value ?? '')}
                />
                <IonInput
                    placeholder="Confirm Password"
                    type="password"
                    onIonChange={(e) => setConfirmPassword(e.detail.value ?? '')}
                />
                <IonButton className='ion-padding' expand="block" onClick={handleResetPassword}>
                    Reset Password
                </IonButton>
                <IonToast
                    isOpen={showToast}
                    onDidDismiss={() => setShowToast(false)}
                    message={toastMessage}
                    duration={3000}
                />
            </IonContent>
        </IonPage>
    );
};

export default ResetPasswordPage;
