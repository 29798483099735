import { Storage } from "@ionic/storage"

export class UserActivityService {
  storage

  constructor() {
    this.storage = new Storage()
    this.storage.create()
  }

  //back fill dummy data, 14 days
  async backFillDummyData() {
    const dummyValues = [3, 0, 13, 6]; // Array of dummy values
    const today = new Date();
    const year = today.getFullYear();
    const yearKey = `views_${year}`;
    const yearData = await this.storage.get(yearKey) || {};
  
    // Backfill the data for the last 4 days
    for (let i = 0; i < dummyValues.length; i++) {
      const dateToModify = new Date();
      dateToModify.setDate(today.getDate() - i); // Adjust the date backwards
      const dateString = `${dateToModify.getFullYear()}-${String(dateToModify.getMonth() + 1).padStart(2, '0')}-${String(dateToModify.getDate()).padStart(2, '0')}`;
  
      yearData[dateString] = dummyValues[i];
    }
  
    await this.storage.set(yearKey, yearData);
  }
  //---------------------------------------------

  // async getQuoteOfDay() {
  //   const quoteKey = "quote_of_day";
  //   const storedQuote = await this.storage.get(quoteKey);
  //   const today = new Date();
  //   //const todayString = today.toISOString().split('T')[0]; <---- this is GMT time or something, NOT LOCAL
  //   const todayString = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`;


  //   // Check if stored quote is from today
  //   if (storedQuote && storedQuote.date === todayString) {
  //     return storedQuote.quote;
  //   } else {
  //     try {
  //       const response = await fetch('https://api.quotable.io/random');
  //       if (!response.ok) throw new Error('Failed to fetch quote');
  //       const quote = await response.json();
        
  //       // Store new quote with today's date
  //       await this.storage.set(quoteKey, { quote, date: todayString });
  //       return quote;
  //     } catch (error) {
  //       console.error('Error fetching quote of the day:', error);
  //       throw error;
  //     }
  //   }
  // }

  // async getQuoteOfDay() {
  //   const quoteKey = "quote_of_day";
  //   const quoteTranslatedKey = "quote_of_day_selected";
  //   const selectedLanguageKey = "selectedLanguageCode";
  //   const storedQuote = await this.storage.get(quoteKey);
  //   const selectedLanguageCode = await this.storage.get(selectedLanguageKey);
  //   const today = new Date();
  //   const todayString = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`;
  
  //   // Check if stored quote is from today and translation is not needed
  //   if (storedQuote && storedQuote.date === todayString && (!selectedLanguageCode || selectedLanguageCode === "en")) {
  //     const storedQuoteTrans = await this.storage.get(quoteTranslatedKey);
  //     return [storedQuote.quote, storedQuoteTrans.quote];
  //   } else {
  //     try {
  //       const response = await fetch('https://api.quotable.io/random');
  //       if (!response.ok) throw new Error('Failed to fetch quote');
  //       const quoteData = await response.json();
  //       const quote = quoteData.content;
  //       const author = quoteData.author;
  
  //       // Store new quote with today's date in original language
  //       await this.storage.set(quoteKey, { quote: quoteData, date: todayString });
  
  //       // Check if translation is needed
  //       if (selectedLanguageCode && selectedLanguageCode !== "en") {
  //         const translatedQuote = await this.translateQuote(quote, "en", selectedLanguageCode);
  //         const translatedQuoteData = { content: translatedQuote, author };
  
  //         // Store translated quote
  //         await this.storage.set(quoteTranslatedKey, { lang: selectedLanguageCode, quote: translatedQuoteData, date: todayString });
  //         console.log("REturning: ", quoteData, translatedQuoteData);
  //         return [quoteData, translatedQuoteData];
  //       } else {
  //         return [quoteData.quote];
  //       }
  //     } catch (error) {
  //       console.error('Error fetching quote of the day:', error);
  //       throw error;
  //     }
  //   }
  // }
async getQuoteOfDay() {
  const quoteKey = "quote_of_day";
  const quoteTranslatedKey = "quote_of_day_selected";
  const selectedLanguageKey = "selectedLanguageCode";
  const storedQuote = await this.storage.get(quoteKey);
  const storedQuoteTrans = await this.storage.get(quoteTranslatedKey);
  const selectedLanguageCode = await this.storage.get(selectedLanguageKey);
  const today = new Date();
  const todayString = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`;

  // Check if stored quote is from today
  if (storedQuote && storedQuote.date === todayString) {
    // Check if language has changed and translation is required
    if (selectedLanguageCode !== "en" && (!storedQuoteTrans || storedQuoteTrans.lang !== selectedLanguageCode)) {
      const translatedQuote = await this.translateQuote(storedQuote.quote.content, "en", selectedLanguageCode);
      const translatedQuoteData = { content: translatedQuote, author: storedQuote.quote.author };
      // Store translated quote
      await this.storage.set(quoteTranslatedKey, { lang: selectedLanguageCode, quote: translatedQuoteData, date: todayString });
      return [storedQuote.quote, translatedQuoteData];
    } else {
      // No translation needed, return stored quote
      return [storedQuote.quote, storedQuoteTrans ? storedQuoteTrans.quote : null];
    }
  } else {
    // Fetch and store new quote
    try {
      console.log("API call to get QUOTES!");
      const response = await fetch('https://api.quotable.io/random');
      if (!response.ok) throw new Error('Failed to fetch quote');
      const quoteData = await response.json();
      
      // Store new quote with today's date
      await this.storage.set(quoteKey, { quote: quoteData, date: todayString });

      if (selectedLanguageCode && selectedLanguageCode !== "en") {
        const translatedQuote = await this.translateQuote(quoteData.content, "en", selectedLanguageCode);
        const translatedQuoteData = { content: translatedQuote, author: quoteData.author };
        await this.storage.set(quoteTranslatedKey, { lang: selectedLanguageCode, quote: translatedQuoteData, date: todayString });
        return [quoteData, translatedQuoteData];
      } else {
        return [quoteData, null];
      }
    } catch (error) {
      console.error('Error fetching quote of the day:', error);
      throw error;
    }
  }
}
//  function to translate the quote
async translateQuote(text, sourceL, targetL) {
  const hard_langs = ["ko", "hi", "zh", "id", "th", "vi", "ja"];
  let apiEndpoint;
  let requestBody;
  let headers = {};

  if (hard_langs.includes(sourceL) || hard_langs.includes(targetL)) {
    apiEndpoint = "https://0tqla5kpmb.execute-api.ap-southeast-1.amazonaws.com/test/";
    requestBody = new URLSearchParams({ from: sourceL, to: targetL, text });
  } else {
    apiEndpoint = "https://yq164ah9bl.execute-api.ap-southeast-1.amazonaws.com/test/";
    requestBody = JSON.stringify({ q: text, source: sourceL, target: targetL, format: "text" });
    headers = { "Content-Type": "application/json" };
  }

  try {
    const response = await fetch(apiEndpoint, {
      method: "POST",
      body: requestBody,
      headers: headers
    });

    const data = await response.json();
    return data.trans || data.translatedText || text;
  } catch (error) {
    console.error('Error translating quote:', error);
    return text; // Return original text in case of an error
  }
}




  //this function ensures there is a year structure to write to
  async initializeYearData(year) {
    const yearKey = `views_${year}`;
    const yearData = await this.storage.get(yearKey);
  
    //console.log("yearData", yearKey, yearData)
    //if no year data, then create it for the entire year
    if (!yearData) {
      let newYearData = {};
      for (let month = 0; month < 12; month++) {
        // Get the number of days in the month
        let daysInMonth = new Date(year, month + 1, 0).getDate();
        for (let day = 1; day <= daysInMonth; day++) {
          let date = new Date(year, month, day);
          const dateString = `${year}-${String(month + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
          newYearData[dateString] = 0;
        }
      }
      await this.storage.set(yearKey, newYearData);
    }
  }
  
  

  //Returns current year and prev year data as an object with the year being the key, If prev year is not available it will just return an empty object for that year
  async getYearData() {
    const currentYear = new Date().getFullYear();
    const prevYear = currentYear - 1;
    const currentYearData = await this.storage.get(`views_${currentYear}`) || {};
    const prevYearData = await this.storage.get(`views_${prevYear}`) || {};

    return {
      [currentYear]: currentYearData,
      [prevYear]: prevYearData
    };
  }


  // Increment the current day's view count by +1
  // async incrementTodayView() {
  //   const today = new Date().toISOString().split('T')[0];
  //   const year = new Date().getFullYear();
  //   const yearKey = `views_${year}`;
  //   const yearData = await this.storage.get(yearKey) || {};
  //   console.log("incrementTodayView data:", today,  yearData)
  //   yearData[today] = (yearData[today] || 0) + 1;
  //   console.log("today's count is: ", yearData[today]);
  //   await this.storage.set(yearKey, yearData);
  // }
  async incrementTodayView() {
    const today = new Date();
    const localDateString = today.getFullYear() + '-' + String(today.getMonth() + 1).padStart(2, '0') + '-' + String(today.getDate()).padStart(2, '0');
    const year = today.getFullYear();
    const yearKey = `views_${year}`;
    const yearData = await this.storage.get(yearKey) || {};
  
    console.log("incrementTodayView data:", localDateString,  yearData);
    yearData[localDateString] = (yearData[localDateString] || 0) + 1;
    console.log("today's count is: ", yearData[localDateString]);
  
    await this.storage.set(yearKey, yearData);
  }
  

  // Set the specified year and date to a value provided by the caller
  async setViewCount(year, date, count) {
    const yearKey = `views_${year}`;
    const yearData = await this.storage.get(yearKey);

    if (yearData && yearData.hasOwnProperty(date)) {
      yearData[date] = count;
      await this.storage.set(yearKey, yearData);
    } else {
      throw new Error('Date not found in the specified year');
    }
  }


  // Resets the counter to zero for a given year and day, if year does not exist an error is returned
  async resetViewCount(year, date) {
    const yearKey = `views_${year}`;
    const yearData = await this.storage.get(yearKey);

    if (yearData && yearData.hasOwnProperty(date)) {
      yearData[date] = 0;
      await this.storage.set(yearKey, yearData);
    } else {
      throw new Error('Date not found in the specified year');
    }
  }


  // Delete the key for a sepcific year, if year '0000' is provided then delete all years
  async deleteYearData(year) {
    if (year === '0000') {
      // Delete all years
      const keys = await this.storage.keys();
      const viewKeys = keys.filter(key => key.startsWith('views_'));
      await Promise.all(viewKeys.map(key => this.storage.remove(key)));
    } else {
      await this.storage.remove(`views_${year}`);
    }
  }


}

const userActivityService = new UserActivityService()
export default userActivityService
