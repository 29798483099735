import {
  IonText,
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonSlides,
} from "@ionic/react"
import React, { useState, useEffect } from "react"
import { useParams } from "react-router"
import SearchBar from "../components/SearchBar/SearchBar"
import SearchResults from "../components/SearchResults"
import "./pages.scss"
import { ChannelSlide, VideoSlide } from "../components/ChannelSlide"
import { useLocation } from "react-router-dom"
import { Storage } from '@ionic/storage';

interface ChannelDetail {
  channelId: string;
  title: string;
  avatar: { url: string }[];
  // Add other properties as needed
}



const HomePage: React.FC = () => {

  const buildDate = process.env.REACT_APP_BUILD_DATE
  const { searchTerm = "new" } = useParams<{ searchTerm: string }>()
  const [showSplash, setShowSplash] = useState(false)
  const [savedChannels, setSavedChannels] = useState<ChannelDetail[]>([]);

  const location = useLocation()

  useEffect(() => {
    const storage = new Storage();
    storage.create();

    const loadSavedChannels = async () => {
      const channels = await storage.get('channels') || [];
      // console.log("Channels SAVED: ", channels);
      setSavedChannels(channels);
    };

    loadSavedChannels();
  }, []);

  /*
  useEffect(() => {
    // Check if the splash screen has been shown in this session
    const splashShown = sessionStorage.getItem('splashShown');

    if (!splashShown) {
      setShowSplash(true);
      sessionStorage.setItem('splashShown', 'true');
    }
  }, []);
*/

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonToolbar>
            <IonTitle>Home</IonTitle>
          </IonToolbar>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton color="primary" />
            </IonButtons>
            <SearchBar />
          </IonToolbar>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen className="ion-padding home">
        
        { /*
        (location.pathname == "/home" || location.pathname == "/home/new") &&
        <IonSlides id="slider" options={{ slidesPerView: "auto", zoom: true, grabCursor: true }} className="categorySlider" >
					<ChannelSlide name="Channel 1" path="/channel/UCX6OQ3DkcsbYNE6H8uQQuVA" image="/assets/Ickle.png" />
					<ChannelSlide name="Channel 2" path="/channel/UCX6OQ3DkcsbYNE6H8uQQuVA" image="/assets/Kickle.png" />
					<ChannelSlide name="Channel 3" path="/channel/UCX6OQ3DkcsbYNE6H8uQQuVA" image="/assets/Ickle.png" />
				</IonSlides>
  */}


{ (location.pathname === '/home' || location.pathname === '/home/new') && (
          savedChannels.length > 0 ? (
<div>
<IonText color="warning" className="home__title">
          Channels list
        </IonText>
            <IonSlides
              id="slider"
              options={{ slidesPerView: 'auto', zoom: true, grabCursor: true }}
              className="categorySlider"
              key={savedChannels.length}
            >
                {savedChannels.map((channel) => (
                  <ChannelSlide
                    key={channel.channelId}
                    name={channel.title}
                    path={`/channel/${channel.channelId}`}
                    image={channel.avatar?.[2]?.url || '/assets/Ickle.png'}
                  />
                ))}
                <ChannelSlide name="TED" path="/channel/UCAuUUnT6oDeKwE6v1NGQxug" image="/assets/ted.jpg" />
                <ChannelSlide name="Learn Burn" path="/channel/UCuxZY9QIXvxkrRJlIWjoUzw" image="/assets/lb.jpg" />
                <ChannelSlide name="Kurzgesagt - In a nutshell" path="/channel/UCsXVk37bltHxD1rDPwtNM8Q" image="/assets/kurzgesagt.jpg" />
            </IonSlides>
            <div style={{ backgroundColor: '#071a2b' }}>
              <IonText color="warning" className="home__title">
                        Pinned Videos
                      </IonText>
              <IonSlides
                id="pinned"
                options={{ slidesPerView: '4', zoom: true, grabCursor: true }}
                className="videoSlider"
                key={savedChannels.length + 1}
              >
                <VideoSlide key="ad" name="What is Space Subs?" path="/video/65VIuqIZbGQ" image="/assets/ad-alpha1-aljE91ZZy-8.jpg" />
                <VideoSlide key="overview" name="Space Subs overview & Usage" path="/video/7uWPb91u-e4" image="/assets/overview-usage-alpha1-T2OvjtJgM0Q.jpg" />
                <VideoSlide key="quotes" name="Quotes: Star Wars" path="/video/JD-UsEO2Ks8" image="/assets/quotes-starwars.jpg" />
                <VideoSlide key="cubetut" name="Cube Tutorial" path="/video/Brf-3cRoASM" image="/assets/cubetut.JPG" />

              </IonSlides>
            </div>

            </div>
          ) : (
            // Render the hardcoded channels if no saved channels are found
            <div>
            <IonSlides
              id="slider"
              options={{ slidesPerView: 'auto', zoom: true, grabCursor: true }}
              className="categorySlider"
            >
              <ChannelSlide name="TED" path="/channel/UCAuUUnT6oDeKwE6v1NGQxug" image="/assets/ted.jpg" />
              <ChannelSlide name="Learn Burn" path="/channel/UCuxZY9QIXvxkrRJlIWjoUzw" image="/assets/lb.jpg" />
              <ChannelSlide name="Kurzgesagt - In a nutshell" path="/channel/UCsXVk37bltHxD1rDPwtNM8Q" image="/assets/kurzgesagt.jpg" />
              <ChannelSlide name="Joe Rogan" path="/channel/UCzQUP1qoWDoEbmsQxvdjxgQ" image="/assets/joe.jpg" />
              <ChannelSlide name="TED ED" path="/channel/UCsooa4yRKGN_zEE8iknghZA" image="/assets/teded.jpg" />
            </IonSlides>
            <div style={{ backgroundColor: '#071a2b' }}>
              <IonText color="warning" className="home__title">
                Pinned Videos
              </IonText>
              <IonSlides
                id="pinned"
                options={{ slidesPerView: 'auto', zoom: true, grabCursor: true }}
                className="videoSlider"
                key={savedChannels.length + 1}
              >
                <VideoSlide key="ad" name="Space Subs Intro" path="/video/65VIuqIZbGQ" image="/assets/ad-alpha1-aljE91ZZy-8.jpg" />
                <VideoSlide key="overview" name="Space Subs overview & Usage" path="/video/T2OvjtJgM0Q" image="/assets/overview-usage-alpha1-T2OvjtJgM0Q.jpg" />

              </IonSlides>
            </div>
            </div>
          )
)}



        <IonText color="warning" className="home__title">
          Results
        </IonText>
        <IonText color="tertiary">
          <div style={{ fontSize: "0.7rem" }}>
            Build Date: {buildDate} *** please add or install page to homescreen, then launch
            from there ***
           </div>
        </IonText>
        {/* Go To <IonRouterLink routerLink='/home'>Home</IonRouterLink> */}
        {searchTerm && <SearchResults searchTerm={searchTerm} />}
       
      </IonContent>

      {/*showSplash && <SplashScreen onEnd={() => setShowSplash(false)} isVisible={showSplash}/> */}
    </IonPage>
  )
}
export default HomePage
