exports.vtt = [
    {
      "startTime": "00:00:03.637",
      "endTime": "00:00:05.309",
      "text": "I got a good time"
    },
    {
      "startTime": "00:00:06.309",
      "endTime": "00:00:08.026",
      "text": "국가간에 우호관계의 국가간에 우호관계의"
    },
    {
      "startTime": "00:00:10.026",
      "endTime": "00:00:14.560",
      "text": "すべての人間 すべての人間"
    },
    {
      "startTime": "00:00:15.026",
      "endTime": "00:00:18.560",
      "text": "4th button with lots of text like a real sentence about a dog and a cat on the moon."
    },
    {
      "startTime": "00:00:18.026",
      "endTime": "00:00:20.560",
      "text": "人人生出嚟就系自由 人人生出嚟就系自由"
    },
    {
      "startTime": "00:00:21.026",
      "endTime": "00:00:23.560",
      "text": "6TH button"
    },
    {
      "startTime": "00:00:24.026",
      "endTime": "00:00:26.560",
      "text": "7TH button"
    },
    {
      "startTime": "00:00:27.026",
      "endTime": "00:00:30.560",
      "text": "8TH button"
    }
  ]