import React from "react"
import { useState, useEffect } from "react"
//import { useNavigate } from "react-router-dom";
import { useHistory } from "react-router-dom"
import { IonIcon, IonFabButton, IonSearchbar } from "@ionic/react"
import { searchOutline } from "ionicons/icons"
import "./SearchBar.scss"
import { Storage } from "@ionic/storage"
import { useSearch } from '../../SearchContext';

const storage = new Storage();
storage.create();


const SearchBar = () => {
  const { updateSearchResults, updateLastClickedIndex } = useSearch();
  const [searchTerm, setSearchTerm] = useState("")
  //const navigate = useNavigate();
  const history = useHistory()

  useEffect(() => {
    // Load the last search term from Ionic Storage if any
    const loadLastSearchTerm = async () => {
      const lastSearchTerm = await storage.get('lastSearchTerm');
      if (lastSearchTerm) {
        setSearchTerm(lastSearchTerm);
      }
    };

    loadLastSearchTerm();
  }, []);


  const handleSearch = async () => {
    if (searchTerm) {
      // Clear search results from context
      updateSearchResults([]);
      updateLastClickedIndex(-1);
      const lowerCaseTerm = searchTerm; //.toLowerCase();
      setSearchTerm(lowerCaseTerm)
      //navigate(`/search/${searchTerm}`)
      await storage.set('lastSearchTerm', lowerCaseTerm);

      // Check if the searchTerm is a YouTube URL and extract video ID
      //const youtubeRegex = /(?:youtube\.com\/watch\?v=|youtu\.be\/)([a-zA-Z0-9_-]+)(?:[&?][^#]*)?/;
      const youtubeRegex = /(?:youtube\.com\/watch\?v=|youtu\.be\/|youtube\.com\/shorts\/)([a-zA-Z0-9_-]+)(?:[&?][^#]*)?/;


      const match = lowerCaseTerm.match(youtubeRegex);
      console.log("youtubeRegex: ", match);

      if (match && match[1]) {
        // If it's a YouTube URL, navigate to /video/:videoId
        const videoId = match[1];
        history.push(`/video/${videoId}`);
      } else {
        // If it's not a YouTube URL, proceed with the regular search
        history.push(`/search/${lowerCaseTerm}`);
      }

      // history.push(`/search/${searchTerm}`)
    } else {
      await storage.remove('lastSearchTerm'); // Remove the last search term if the search bar is cleared
    }
  }

  const handleInputChange = async (e: CustomEvent) => {
    const newSearchTerm = e.detail.value!;
    setSearchTerm(newSearchTerm);

    if (!newSearchTerm) {
      await storage.remove('lastSearchTerm'); // Remove the last search term if the search bar is manually cleared
    }
  };



  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <IonSearchbar
        className="custom-searchbar"
        animated={true}
        value={searchTerm}
        onKeyUp={(e) => e.key === "Enter" && handleSearch()}
        onIonChange={handleInputChange}
        debounce={150}
        enterkeyhint="search"
        placeholder="Type or Youtube link"
        style={{ flex: 1 }} // This makes the search bar take the remaining space
      ></IonSearchbar>

      <IonFabButton size="small" onClick={handleSearch}>
        <IonIcon icon={searchOutline}></IonIcon>
      </IonFabButton>
    </div>
  )
}

export default SearchBar
