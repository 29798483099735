import { Storage } from "@ionic/storage"
import {
  formatTime,
  formatTimeRP,
} from "../utilities/utilities"


export class HistoryVideosService {
  static MAX_HISTORY = 100
  storage

  constructor() {
    this.storage = new Storage()
    this.storage.create()
  }

  async addToHistory(video) {
    let history = (await this.storage.get("history")) || []
    
    // Initialize userViews count
    let userViewsCount = 1;

    // // Remove the old entries of the same video from the history
    // history = history.filter(
    //   (videoItem) =>
    //     videoItem.id !== video.id.videoId && videoItem.id !== video.id
    // )
  // Find the old entry of the same video and get its userViews count
  const oldEntryIndex = history.findIndex(
    (videoItem) =>
      videoItem.id === video.id.videoId || videoItem.id === video.id
  );

  if (oldEntryIndex !== -1) {
    userViewsCount = (history[oldEntryIndex].userViews || 0) + 1;
    history.splice(oldEntryIndex, 1); // Remove the old entry
  }

    if (history.length > HistoryVideosService.MAX_HISTORY) {
      // remove the oldest entry if MAX_HISTORY is exceeded
      history.pop(); // Use pop() to remove the last (oldest) item
    }

    const videoData = {
      id: video.id?.videoId || video.id || video.videoId,
      title: video.snippet?.title || video.title,
      channelTitle: video.snippet?.channelTitle || video.channelTitle,
      channelId: video.snippet?.channelId || video.channelId,
      description: video.snippet?.description || video.description,
      viewCount: video.viewCount || 0,
      publishDate: video.publishDate || "",
      lengthText: video.lengthText || formatTimeRP(parseInt(video.lengthSeconds)) || "",
      userViews: userViewsCount,
      thumbnail:
        video.snippet?.thumbnails?.medium?.url || video.thumbnail[3]?.url || video.thumbnail[0]?.url,
    }

    // Add the new entry to the history
    // history.push(videoData)
    history.unshift(videoData) //add to the front of the list, so most recent on top.

    await this.storage.set("history", history)
  }

  async removeFromHistory(videoId) {
    let history = (await this.storage.get("history")) || []

    history = history.filter((video) => video.id !== videoId)
    await this.storage.set("history", history)
  }

  async getHistory() {
    const history = await this.storage.get("history")
    return history || []
  }

  async clearHistory() {
    await this.storage.remove("history")
  }
}

const historyVideosService = new HistoryVideosService()
export default historyVideosService
