import React, { useState, useEffect } from "react"
import {
  IonGrid,
  IonRow,
  IonCol,
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonSearchbar,
  IonMenuButton,
  IonButtons,
} from "@ionic/react"
import favoriteVideosService from "../utils/FavoriteVideosService"
import notesService from "../utils/NotesService"
import { useHistory } from "react-router-dom"

import { useMediaQuery } from "react-responsive"
import VideoCardCompact from "../components/VideoCard/VideoCardCompact"
import VideoCardGrid from "../components/VideoCard/VideoCardGrid"

interface FavoriteVideo {
  id: string
  title: string
  channelTitle: string
  description: string
  thumbnail: string
  lengthText: string;
  publishDate: string;
  viewCount: string;
}

interface NoteVideo {
  text: string;
  videoData: {
    id: string;
    title: string;
    channelTitle: string;
    description: string;
    thumbnail: string;
    lengthText: string;
    publishDate: string;
    viewCount: string;
  };

}


const NotesPage: React.FC = () => {
  //Determine size of device
  const isTabletOrDesktop = useMediaQuery({ query: "(min-width: 768px)" })

  //const [favorites, setFavorites] = useState([]);
  const [notes, setNotes] = useState<NoteVideo[]>([]);
  const [favorites, setFavorites] = useState<FavoriteVideo[]>([])
  const [searchTerm, setSearchTerm] = useState("")
  const history = useHistory()

  useEffect(() => {
    (async () => {
      const notes = await notesService.getNotes()
      //make sure notes is in the correct format, if not then correct it and save it.

      setNotes(notes)
    })()
  }, [])

  useEffect(() => {
    return history.listen(() => {
      // listen for changes in history
      (async () => {
        const favs = await notesService.getNotes()
        setNotes(notes)
      })()
    })
  }, [history])

  const handleSearch = (e: any) => {
    setSearchTerm(e.target.value)
  }

  const filteredNotes = notes.filter((note) =>
    note.videoData.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    note.text.toLowerCase().includes(searchTerm.toLowerCase())
  );


  // const adjustedNotes = filteredNotes.map((video) => ({
  //   id: { videoId: video.id },
  //   snippet: {
  //     title: video.title,
  //     channelTitle: video.channelTitle,
  //     description: video.description,
  //     thumbnails: {
  //       medium: {
  //         url: video.thumbnail,
  //       },
  //     },
  //   },
  // }))
  const adjustedNotes = filteredNotes.map((note) => ({
    id: { videoId: note.videoData.id },
    snippet: {
      title: note.videoData.title,
      channelTitle: note.videoData.channelTitle,
      description: note.videoData.description,
      thumbnails: {
        medium: {
          url: note.videoData.thumbnail,
        },
      },
    },
    text: note.text,
    lengthText : note.videoData.lengthText || "",
    publishDate : note.videoData.publishDate || "",
    viewCount : note.videoData.viewCount || "",
  }));


  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
        <IonButtons slot="start">
              <IonMenuButton color="primary" />
          </IonButtons>
          <IonTitle>My Notes</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className="compact-list-padding">
        <IonSearchbar
          className="custom-searchbar-local"
          value={searchTerm}
          onIonChange={handleSearch}
          debounce={500}
          enterkeyhint="search"
          placeholder="Search Notes"
        ></IonSearchbar>
        {/*<Videos videos={filteredFavorites} /> */}
        {/* <Videos videos={adjustedFavorites} /> */}
        <IonGrid>
          <IonRow>
            {adjustedNotes.map((video) => (
              <IonCol
                style={{ padding: 0 }}
                size="12"
                size-md="4"
                size-lg="2.3"
              >
                {isTabletOrDesktop ? (
                  <VideoCardGrid video={{ ...video, text: video.text }} />
                ) : (
                  <VideoCardCompact video={{ ...video, text: video.text }} />
                )}

                {/*<VideoCardCompact video={video} key={video.id.videoId} /> */}
              </IonCol>
            ))}
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  )
}

export default NotesPage
