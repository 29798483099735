import { Storage } from "@ionic/storage"
import {
  formatTime,
  formatTimeRP,
} from "../utilities/utilities"

export class FavoriteVideosService {
  static MAX_FAVORITES = 300
  storage

  constructor() {
    this.storage = new Storage()
    this.storage.create()
  }

  async addToFavorites(video) {
    const favorites = (await this.storage.get("favorites")) || []

    if (favorites.length >= FavoriteVideosService.MAX_FAVORITES) {
      throw new Error(
        "Maximum number of favorite videos reached, please clear old favorites"
      )
    }

    // Access the video data from the snippet and id objects in the payload
    const videoData = {
      id: video.id?.videoId || video.id || video.videoId,
      title: video.snippet?.title || video.title,
      channelTitle: video.snippet?.channelTitle || video.channelTitle,
      channelId: video.snippet?.channelId || video.channelId,
      description: video.snippet?.description || video.description,
      viewCount: video.viewCount || 0,
      publishDate: video.publishDate || "",
      lengthText: video.lengthText || formatTimeRP(parseInt(video.lengthSeconds)) || "",
      thumbnail:
        video.snippet?.thumbnails?.medium?.url || video.thumbnail[3]?.url || video.thumbnail[0]?.url,
    }
console.log("FavoriteVideosService video: ", video);
    // favorites.push(videoData)  replaced this which adds to the back of the list
    favorites.unshift(videoData) //add tot he front of the list so most recent is on top.
    await this.storage.set("favorites", favorites)
  }

  async removeFromFavorites(videoId) {
    let favorites = (await this.storage.get("favorites")) || []

    favorites = favorites.filter((video) => video.id !== videoId)
    await this.storage.set("favorites", favorites)
  }

  async getFavorites() {
    const favorites = await this.storage.get("favorites")
    return favorites || []
  }

  async updateFavorite(videoId, updatedData) {
    let favorites = (await this.storage.get("favorites")) || []

    const videoIndex = favorites.findIndex((video) => video.id === videoId)

    if (videoIndex !== -1) {
      favorites[videoIndex] = { ...favorites[videoIndex], ...updatedData }
      await this.storage.set("favorites", favorites)
    }
  }
}

const favoriteVideosService = new FavoriteVideosService()
export default favoriteVideosService
