import axios from 'axios';
import moment from 'moment';
import { ytCategoryMap } from "../utils/constants";
import {auth} from '../firebase';

//Call AWS API Gateway instead of YT api endpts directly
//const API_SEARCH_URL = 'https://i9qxuqj9pa.execute-api.ap-southeast-1.amazonaws.com/test/ytsearch';

// const API_SEARCH_URL3 = 'https://8agzpivd41.execute-api.ap-southeast-1.amazonaws.com/test/ytsearch';
// const API_FEED_URL3 = 'https://cgyqh2iiee.execute-api.ap-southeast-1.amazonaws.com/test/ythome3';

const API_SEARCH_URL3 = process.env.REACT_APP_API_SEARCH_URL3;
const API_FEED_URL3 = process.env.REACT_APP_API_FEED_URL3;



// DEV
// const API_SEARCH_URL4 = "https://searchyt-3xvvc5q44q-uc.a.run.app";
// const API_SEARCH_URL5 = "https://channelvids-3xvvc5q44q-uc.a.run.app";

// STAGING
// const API_SEARCH_URL4 = "https://searchyt-tcvzndpjqa-uc.a.run.app";

const API_SEARCH_URL4 = process.env.REACT_APP_API_SEARCH_URL4;


// const API_SEARCH_URL5 = "https://channelvids-tcvzndpjqa-uc.a.run.app";
const API_SEARCH_URL5 = process.env.REACT_APP_API_SEARCH_URL5;

const API_VIDEO_DET = process.env.REACT_APP_API_VIDEO_DET;
const API_DL_URL = process.env.REACT_APP_API_DL;

/*
export const apiYT = async (url) => {
  const response = await axios.get(`${API_GATEWAY_URL}/${url}`);

  return response.data;
}
*/

//OLD VIDEO DETAILS
// export const apiYT = async (videoId) => {
//   console.log("-----Called new vid details apiYT-----");
//   const options = {
//     method: 'GET',
//     url: 'https://f7zyojmf9c.execute-api.ap-southeast-1.amazonaws.com/test/',
//     params: {
//       part: 'contentDetails,snippet,statistics',
//       id: videoId
//     }
//   };
//   const  { data } = await axios.request(options);
//   return data;
// }

//NEW VIDEO DETAILS API3 --------------------------------
export const apiYT = async (videoId) => {
  

  const options = {
    method: 'GET',
    url: API_VIDEO_DET,
    params: {
      id: videoId,
      geo: 'US',
      lang: 'en'
    }
  };
  
  const  { data } = await axios.request(options);
  //console.log("-----Called new vid details apiYT3-----", data);
  return data;
}


//NEW YTV3ALT version for video SEARCH or HOME listings --------------
export const apiYTALT = async (searchObj) => {
  
  // const url = searchObj.query === 'new' ? API_FEED_URL3 : API_SEARCH_URL4;
  // console.log("-----Called apiYTALT AWS api gateway-----:", url, searchObj);

  if (searchObj.query === 'new') {
    searchObj.query = ''
  }

  // Get the current user's ID token from Firebase authentication
  const idToken = await auth.currentUser.getIdToken(true);
  console.log("apiYTALT FB idToken: ", idToken)


  const ytv3altOptions = {
    method: 'GET',
    url: API_SEARCH_URL4,
    headers: {
      // Include the ID token in the Authorization header
      Authorization: `Bearer ${idToken}`
    },
    params: {
      qu: searchObj.query,
      geo: searchObj.geo,
      lang: searchObj.lang,
      type: 'video',
      token: searchObj.token,
    }
  };
  // const  { data } = await axios.request(ytv3altOptions);
  // console.log("Returned ALL apiYTALT:", data);
  // return data;

  try {
    const { data } = await axios.request(ytv3altOptions);
    console.log("Returned ALL apiYTALT:", data);
    return data;
  } catch (error) {
    console.error("zError calling apiYTALT:", error);
    // return { error: error.code ? error.response.data : "Unknown error occurred." };
    throw error;
  }


}

//NEW YTV3ALT version for video CHANNEL listings --------------
export const apiYTALTCH = async (searchObj) => {
  
  const url = API_SEARCH_URL5;

  // Get the current user's ID token from Firebase authentication
  const idToken = await auth.currentUser.getIdToken(true);
  console.log("apiYTALTCH FB idToken: ", idToken)

  const ytv3altOptions = {
    method: 'GET',
    url: url,
    headers: {
      // Include the ID token in the Authorization header
      Authorization: `Bearer ${idToken}`
    },
    params: {
      channelid: searchObj.channelid,
      chtoken: searchObj.chtoken
    }
  };
  const  { data } = await axios.request(ytv3altOptions);
  //console.log("Returned apiYTALTCH:", data.data);
  return data;
}


//Request to backend for video subs -----------------------------
//Local Server:
// const API_DL_URL = 'http://0.0.0.0:8000'; //local
//DEV Server: 


export async function downloadYoutubeSubtitles(videoId, origLang, translatedLang = "en", duration, caption, category) {


  const categoryId = ytCategoryMap[category] || '22';

  console.log("-----Called downloadYoutubeSubtitles ----- categoryId:", categoryId);
  if (duration == "") {
    return 'NOT_VALID';
  }

  // Parse ISO 8601 duration to a duration object
  const parsedDuration = moment.duration(duration);

  // Convert the duration to hours
  const durationInHours = parsedDuration.asHours();

  // Only execute the try block if duration is less than 5 hours, FE should notify user this video cannot be subbed.
  if (durationInHours > 5) {
    return 'MAX_ERR';
  }

  try {
    //console.log("[Called downloadYoutubeSubtitles]",videoId , originalLang , translatedLang ,duration ,caption);
    //YT api caption indicates if native captions exist
    //const response = await axios.post(`${API_DL_URL}/youtube/${videoId}/subtitles`, {
    // const response = await axios.post(`${API_DL_URL}/youtubedl`, {   // Local
    const response = await axios.post(API_DL_URL, {    // Dev
      orig_lang: origLang,
      translated_lang: translatedLang,
      duration,
      caption,
      categoryId,
      video_id : videoId
    });
    return response.data;
  } catch (error) {
    console.error('Error while downloading YouTube subtitles:', error);
    return null;
  }
}


//converts ISO8601 to integer seconds
export function convertYouTubeDurationToSeconds(duration) {
  const match = duration.match(/PT(\d+H)?(\d+M)?(\d+S)?/);

  const hours = (parseInt(match[1]) || 0);
  const minutes = (parseInt(match[2]) || 0);
  const seconds = (parseInt(match[3]) || 0);

  return (hours * 3600) + (minutes * 60) + seconds;
}
