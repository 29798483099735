import { IonApp, setupIonicReact } from "@ionic/react"
import { IonReactRouter } from "@ionic/react-router"
import Zmain from "./components/zmain"
import {AuthContextProvider} from "./auth"
import { useState, useEffect } from "react"
import { SearchProvider } from './SearchContext';
import userActivityService from "./utils/UserActivityService"

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css"

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css"
import "@ionic/react/css/structure.css"
import "@ionic/react/css/typography.css"

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css"
import "@ionic/react/css/float-elements.css"
import "@ionic/react/css/text-alignment.css"
import "@ionic/react/css/text-transformation.css"
import "@ionic/react/css/flex-utils.css"
import "@ionic/react/css/display.css"

/* Theme variables */
import "./theme/variables.css"
import "./App.scss"

setupIonicReact()

const App: React.FC = () => {
  //const location = useLocation(); // get current route
  //const currentPage = location.pathname.split('/')[1];
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    const initializeData = async () => {
      const currentYear = new Date().getFullYear().toString();
      await userActivityService.initializeYearData(currentYear);
    };

    initializeData();
  }, []);

  return (
    <IonApp>
      <AuthContextProvider>
      <SearchProvider>
        <IonReactRouter>
          <Zmain />
        </IonReactRouter>
      </SearchProvider>
      </AuthContextProvider>
    </IonApp>
  )
  /*{
    }

  return (
    <IonApp>
      <IonReactRouter>
        <IonSplitPane contentId="main" when="(min-width: 2992px)">
          <Menu />

          <IonRouterOutlet id="main">
            <IonTabs>
              <IonRouterOutlet id="tabs">
                <Route path="/" exact={true}>
                  <Redirect to="/home/new" />
                </Route>

                <Route path="/home" exact={true}>
                  <Redirect to="/home/new" />
                </Route>

                <Route path="/video/:id" component={Zplayer} />

                <Route path="/channel/:id">
                  <ChannelDetail />
                </Route>

                <Route path="/search/:searchTerm" exact={true}>
                  <HomePage />
                </Route>

                <Route path="/home/:searchTerm">
                  <HomePage />
                </Route>

                <Route path="/player">
                  <VideoPlayerPage />
                </Route>

                <Route path="/zplayer/:id">
                  <Zplayer />
                </Route>

                <Route path="/srtplayer">
                  <SrtPlayer />
                </Route>

                <Route path="/favorites">
                  <FavoritesPage />;
                </Route>

                <Route path="/myhistory">
                  <HistoryPage />;
                </Route>
              </IonRouterOutlet>

              <IonTabBar slot="bottom" className={shouldHideTabBar ? 'hide-tab-bar' : ''}>
                <IonTabButton tab="home" href="/home">
                  <IonIcon icon={homeOutline} />
                  <IonLabel>Home</IonLabel>
                </IonTabButton>

                <IonTabButton tab="favorites" href="/favorites">
                  <IonIcon icon={heartOutline} />
                  <IonLabel>Favorites</IonLabel>
                </IonTabButton>

                <IonTabButton tab="history" href="/myhistory">
                  <IonIcon icon={albumsOutline} />
                  <IonLabel>History</IonLabel>
                </IonTabButton>
              </IonTabBar>

            </IonTabs>
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
  )  
  }*/
}

export default App
