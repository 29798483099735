import React, { useState, useEffect } from "react";
import {
  IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonButton,
  IonInput, IonItem, IonList, IonMenuButton, IonButtons, IonBackButton
} from "@ionic/react";
import TodoItem from '../components/Todo/TodoItem';
import "./pages.scss";
import { Storage } from "@ionic/storage";

const storage = new Storage();
storage.create();

interface Task {
    id: number;
    text: string;
    completed: boolean;
  }


const TodoPage: React.FC = () => {
  const [tasks, setTasks] = useState<Task[]>([]);
  const [text, setText] = useState('');

  useEffect(() => {
    loadTasks(); // Load tasks on component mount
  }, []);

  const loadTasks = async () => {
    const loadedTasks = await storage.get("todos") || [{ id: 1, text: 'Watch a language video.', completed: false }];
    setTasks(loadedTasks);
  };

  const saveTasks = async (newTasks) => {
    await storage.set("todos", newTasks);
  };

  function addTask(text) {
    if (!text) return;
    const newTask = { id: Date.now(), text, completed: false };
    const newTasks = [newTask, ...tasks];
    setTasks(newTasks);
    setText('');
    saveTasks(newTasks);
  }

  function toggleCompleted(id) {
    const newTasks = tasks.map(task => task.id === id ? { ...task, completed: !task.completed } : task);
    setTasks(newTasks);
    saveTasks(newTasks);
  }

  function deleteTask(id) {
    const newTasks = tasks.filter(task => task.id !== id);
    setTasks(newTasks);
    saveTasks(newTasks);
  }

  function updateTaskText(id, newText) {
    const newTasks = tasks.map(task => task.id === id ? { ...task, text: newText } : task);
    setTasks(newTasks);
    saveTasks(newTasks);
  }

  const completedCount = tasks.filter(task => task.completed).length;
  const totalCount = tasks.length;

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton default-href="/home/new" />
          </IonButtons>
          <IonTitle>ToDos</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="todo-banner">
          <h1 style={{ textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)', color: 'rgb(255, 255, 255)' }}>
            Tasks Done: {completedCount} / {totalCount}
          </h1>
        </div>
        <IonItem className="custom-searchbar-local">
          <IonInput
            value={text}
            placeholder="Add task in target language"
            onIonChange={e => setText(e.detail.value!)}
            onKeyPress={e => { if (e.key === 'Enter') addTask(text) }}
            maxlength={256}
          />
          <IonButton slot="end" onClick={() => addTask(text)}>Add</IonButton>
        </IonItem>
        <IonList className="todo-list">
          {tasks.length > 0 ? tasks.map(task => (
            <TodoItem
              key={task.id}
              task={task}
              deleteTask={deleteTask}
              toggleCompleted={toggleCompleted}
              updateTaskText={updateTaskText}
            />
          )) : (
            <h2>Write in your target language!</h2>
          )}
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default TodoPage;
