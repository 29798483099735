// components/VideoCardCompactFavorite.tsx

import React, { useState, useEffect } from "react"
import {
  IonRouterLink,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonImg,
  IonLabel,
  IonIcon,
} from "@ionic/react"
import {
eyeOutline
} from "ionicons/icons"
import HeartComponent from "../HeartComponent"
import favoriteVideosService from "../../utils/FavoriteVideosService"
import "./VideoCard.scss"
import { extractISODate } from "../../utilities/utilities"

interface VideoProps {
  id: {
    videoId?: string
  }
  snippet?: {
    title?: string
    channelId?: string
    channelTitle?: string
    thumbnails?: {
      medium?: {
        url?: string
      }
    }
    description?: string
  }
  title?: string
  channelId?: string
  channelTitle?: string
  description?: string
  thumbnail?: string
  text?: string;
  lengthText: string;
  publishDate: string;
  viewCount: string;
}

const VideoCardGrid = ({ video }: { video: VideoProps }) => {
  const videoId = video.id.videoId || video.id
  const title = video.snippet?.title || video.title
  const channelId = video.snippet?.channelId || video.channelId
  const channelTitle = video.snippet?.channelTitle || video.channelTitle
  const description = video.snippet?.description || video.description
  const thumbnailUrl = video.snippet?.thumbnails?.medium?.url || video.thumbnail
  const lengthText = video.lengthText || "";
  const publishDate = video.publishDate || "";
  const viewCount = video.viewCount || "";
  const isLocalVideo = channelTitle === "LOCAL";

// console.log("VideoCardGrid video INFO: ", video);
  const [isFavorite, setIsFavorite] = useState(false)

  useEffect(() => {
    ;(async () => {
      const favorites = await favoriteVideosService.getFavorites()
      setIsFavorite(!!favorites.find((video) => video.id === videoId))
    })()
  }, [videoId])

  const handleFavoriteClick = async (event: React.MouseEvent) => {
    event.preventDefault()
    try {
      if (isFavorite) {
        await favoriteVideosService.removeFromFavorites(videoId)
        setIsFavorite(false)
      } else {
        await favoriteVideosService.addToFavorites(video)
        setIsFavorite(true)
      }
    } catch (error) {
      console.error(error)
    }
  }

   {/*<IonCard className={video.text? "card-grid":"card"}> */}
  return (
    
     
      <IonCard className={video.text ? "card card-with-notes" : "card"}>
        <div className="card-content">

        <div className="image-wrapper">
          {isLocalVideo ? (
            <IonImg src={thumbnailUrl} alt={title} />
          ) : (
            <IonRouterLink routerLink={`/video/${videoId}`}>
              <IonImg src={thumbnailUrl} alt={title} />
            </IonRouterLink>
          )}

            <HeartComponent
                className="vheart-icon"
                solid={isFavorite}
                onClick={handleFavoriteClick}
                size="large"
              />
            <div style={{backgroundColor:"black", position: "absolute", top: "1px",}}>{lengthText}</div>
        </div>

          <div className="card-details">
            <IonCardHeader className="card-header">
            
            {isLocalVideo ? (
              <IonCardTitle className="title">
                {title}
              </IonCardTitle>
            ) : (
              <IonRouterLink routerLink={`/video/${videoId}`}>
                <IonCardTitle className="title">
                  {(title || "").length > 60
                    ? (title || "").substr(0, 60) + "..."
                    : title || "[...]"}
                </IonCardTitle>
              </IonRouterLink>
            )}

              {(channelId && channelId != 'LOCAL') ? (
                <IonRouterLink routerLink={`/channel/${channelId}`}>
                    <IonCardSubtitle className="subtitle">
                        {channelTitle}
                      </IonCardSubtitle>
                    </IonRouterLink>
                  ) : (
                    <IonCardSubtitle className="subtitle">
                      {channelTitle}
                    </IonCardSubtitle>
                  )}


                <div className="subtitle2">{Number(viewCount).toLocaleString()} <IonIcon icon={eyeOutline}/> · {extractISODate(publishDate)}</div>
            </IonCardHeader>

            {/* outline color #8ab4f8 */}
            {isLocalVideo ? (
              <div style={video.text ? { borderTop: '1px solid #7298d5', paddingBottom: '5px' } : {}}>
                {video.text && (
                  <>
                  <IonLabel>Notes:</IonLabel>
                  <IonCardContent className="note-text">
                    
                    {video.text}
                  </IonCardContent>
                  </>
                )} 
              </div>
            ):(
            <IonRouterLink routerLink={`/video/${videoId}`}>
              <div className="description" style={video.text ? { borderBottom: '1px solid #7298d5', paddingBottom: '5px' } : {}}>
                {(description || "").length > 80
                  ? (description || "").substr(0, 60) + "..."
                  : description || "[...]"}
              </div>
              { /*<IonCardContent className="description" style={video.text ? { borderBottom: '1px solid #7298d5', paddingBottom: '5px' } : {}}>
                {(description || "").length > 80
                  ? (description || "").substr(0, 60) + "..."
                  : description || "[...]"}
                  </IonCardContent> */}
              
              
              {video.text && (
                <>
                <IonLabel>Notes:</IonLabel>
                <IonCardContent className="note-text">
                  
                  {video.text}
                </IonCardContent>
                </>
              )}
            </IonRouterLink>
            )}

          </div>
        </div>
      </IonCard>

  )
}

export default VideoCardGrid
