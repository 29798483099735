/*
JMR
*/
import { IonIcon } from "@ionic/react"
import { bookmark, bookmarkOutline } from "ionicons/icons"

interface BookmarkComponentProps {
  solid: boolean
  onClick: (event: React.MouseEvent) => void // change this line
  size?: "small" | "medium" | "large"
  className?: string
}

const BookmarkComponent: React.  FC<BookmarkComponentProps> = ({
  solid,
  onClick,
  size,
  className,
}) => {
  // map size to actual values
  const sizeMapping = {
    small: "20px",
    medium: "24px",
    large: "28px",
  }

  const actualSize = size ? sizeMapping[size] : sizeMapping["medium"] // Default to 'medium' if size is not provided

  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation()
    onClick(event)
  }

  return (
    <IonIcon
      icon={solid ? bookmark : bookmarkOutline}
      onClick={handleClick}
      style={{
        cursor: "pointer",
        fontSize: actualSize,
        color: solid ? "red" : "lightgrey",
      }}
      className={className}
    />
  )
}

export default BookmarkComponent
