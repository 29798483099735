import {
  IonButton,
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonRouterLink,
  IonTitle,
  IonToolbar,
  IonLoading,
} from "@ionic/react"
import { useParams } from "react-router"
import Videos from "../components/VideoCard/Videos"
import { apiYTALTCH } from "../utils/apiYT"
import { useState, useEffect } from "react"
import ChannelCard from "../components/ChannelCard"
import { useSearch } from '../SearchContext';

interface Thumbnail {
  url: string;
  width: number;
  height: number;
}

interface Video {
  type: string;
  videoId: string;
  title: string;
  channelTitle: string;
  channelId: string;
  channelHandle: string;
  channelThumbnail: Thumbnail[];
  description: string;
  viewCount: string;
  publishedTimeText: string;
  publishDate: string;
  publishedAt: string;
  lengthText: string;
  badges: string[];
  thumbnail: Thumbnail[];
  richThumbnail: Thumbnail | null; // Assuming richThumbnail has the same structure as thumbnail, or null if not present
}

const ChannelDetail: React.FC = () => {
  const [channelDetail, setChannelDetail] = useState()
  const [pagination, setPagination] = useState('')
  const [videos, setVideos] = useState<Video[]>([])
  const { id } = useParams<{ id: string }>()
  const [showLoading, setShowLoading] = useState(false);
  const [requestTimedOut, setRequestTimedOut] = useState(false);
  const { searchResultsCh, updateSearchResultsCh , lastChannel, updateLastChannel} = useSearch(); //search CONTEXT

  //const [searchTerm, setSearchTerm] = useState('Popular Languages');
  //const [videos, setVideos] =  useState([]);

  // const handleChange = (ev: Event) => {
  //   ev.preventDefault()
  //   let query = ""
  //   const target = ev.target as HTMLIonSearchbarElement
  //   if (target) query = target.value!.toLowerCase()
  // }

  // //on load get channel videos
  // useEffect(() => {
  //   if (searchResultsCh.length > 0) {
  //     setVideos(searchResultsCh);
  //     setChannelDetail(lastChannel);
  //     return;
  //   }

  //   setShowLoading(true);
  //   setRequestTimedOut(false);
  //   //set 8sec timer for IonLoading indicator
  //   let timeoutId = setTimeout(() => {
  //     setShowLoading(false);
  //     setRequestTimedOut(true);
  //   }, 8000); // 8 seconds timeout

  //   const fetchResults = async () => {
  //     const searchObj = {
  //       channelid: id,
  //     }
  //     clearTimeout(timeoutId)
  //     console.log("ChannelDetail: calling apiYTALTCH with ", searchObj);
  //     const data = await apiYTALTCH(searchObj)
  //     setPagination(data.continuation); // Set pagination data
  //     console.log("CHANNEL Init DETAILS: ", data.continuation)
  //     setChannelDetail(data?.["meta"])
  //     setShowLoading(false);

  //     // const videosData = await apiYT(
  //     //   `search?channelId=${id}&part=snippet,id&order=date`
  //     // )
  //     setVideos(data?.["data"])
  //     updateSearchResultsCh(data?.["data"]); // Update the context with new results
  //     updateLastChannel(data?.["meta"]);
  //     //console.log("videos for channel:");
  //     //console.log(videosData);
  //   }

  //   fetchResults()
  // }, [id])

    //when the user clicks on "More" button to load additional videos
    // const handlePagination = async () => {
    //   console.log("SENDING Pagination: ", pagination);
    //   setShowLoading(true);
    //   const searchObj = {
    //     channelid: id,
    //     chtoken: pagination, // pagination token for the next set of videos
    //   };
    //       //set 8sec timer for IonLoading indicator
    //   let timeoutId = setTimeout(() => {
    //     setShowLoading(false);
    //     setRequestTimedOut(true);
    //   }, 8000); // 8 seconds timeout

    //   const data = await apiYTALTCH(searchObj)
    //   clearTimeout(timeoutId)
    //   setPagination(data.continuation); // Set pagination data
    //   console.log("CHANNEL Cont Pagination: ", data.continuation)
    //   //setChannelDetail(data?.["meta"])
      
    //   setVideos(prevVideos => [...prevVideos, ...data.data]);
    //   updateSearchResultsCh(prevVideos => [...prevVideos, ...data.data]); // Update the context with new results
    //   setShowLoading(false);
  
    // };

    useEffect(() => {
      if (searchResultsCh.length > 0) {
        setVideos(searchResultsCh);
        setChannelDetail(lastChannel);
        return;
      }
    
      setShowLoading(true);
      setRequestTimedOut(false);
    
      // Set an 8-second timer for the IonLoading indicator
      let timeoutId = setTimeout(() => {
        setShowLoading(false);
        setRequestTimedOut(true);
      }, 8000); // 8 seconds timeout
    
      const fetchResults = async () => {
        try {
          const searchObj = {
            channelid: id,
          };
          const data = await apiYTALTCH(searchObj);
    
          clearTimeout(timeoutId); // Clear the timer as the API call was successful
    
          setPagination(data.continuation); // Set pagination data
          //console.log("CHANNEL Init DETAILS: ", data.continuation);
          setChannelDetail(data?.["meta"]);
          setVideos(data?.["data"]);
          updateSearchResultsCh(data?.["data"]); // Update the context with new results
          updateLastChannel(data?.["meta"]);
        } catch (error) {
          console.error("Error during API call:", error);
          clearTimeout(timeoutId); // Clear the timer as there was an error
          setShowLoading(false);
          setRequestTimedOut(true); // Optionally set a state to indicate the request failed
          // Additional error handling can be done here
        } finally {
          setShowLoading(false); // Ensure loading is always hidden after the API call
        }
      };
    
      fetchResults();
    }, [id, searchResultsCh, lastChannel]); // Ensure to include all dependencies here

    

    const handlePagination = async () => {
      console.log("SENDING Pagination: ", pagination);
      setShowLoading(true);
      const searchObj = {
        channelid: id,
        chtoken: pagination, // pagination token for the next set of videos
      };
    
      // Set an 8-second timer for the IonLoading indicator
      let timeoutId = setTimeout(() => {
        setShowLoading(false);
        setRequestTimedOut(true);
      }, 8000); // 8 seconds timeout
    
      try {
        const data = await apiYTALTCH(searchObj);
        clearTimeout(timeoutId); // Clear the timer as the API call was successful
    
        setPagination(data.continuation); // Set pagination data
        console.log("CHANNEL Cont Pagination: ", data.continuation);
    
        setVideos(prevVideos => [...prevVideos, ...data.data]);
        updateSearchResultsCh(prevVideos => [...prevVideos, ...data.data]); // Cache to context with new  results
      } catch (error) {
        console.error("Error during API call:", error);
        clearTimeout(timeoutId); // Clear the timer as there was an error
        setShowLoading(false);
        setRequestTimedOut(true); // Optionally set a state to indicate the request failed
        // Additional error handling can be done here
      } finally {
        setShowLoading(false); // Ensure loading is always hidden after the API call
      }
    };
    


  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton default-href="/home/new"/>
          </IonButtons>
          <IonTitle>Channel</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen className="ion-padding">
        <ChannelCard channelDetail={channelDetail} />
        {videos && <Videos videos={videos} />}
        <IonButton expand="block" fill="outline" color="medium" onClick={handlePagination}>More</IonButton>
      </IonContent>
      
      <IonLoading
        isOpen={showLoading}
        message={'Loading videos...'}
      />
    </IonPage>
  )
}

export default ChannelDetail
