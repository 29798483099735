import { Storage } from "@ionic/storage"
import {
  formatTime,
  formatTimeRP,
} from "../utilities/utilities"


export class WordsService {
  static MAX_HISTORY = 100
  storage

  constructor() {
    this.storage = new Storage()
    this.storage.create()
  }

  async addToWords(video, sourceLang, sourceText, sourceTile, targetLang, targetText, targetTile) {
    let words = (await this.storage.get("recentwords")) || []
    
    // Initialize userViews count
    let userViewsCount = 1;

    // Find the old entry of the same vocabulary word/phrase and get its userViews count
    const oldEntryIndex = words.findIndex(
      (wordItem) =>
      wordItem.sourceText === sourceText
    );

    if (oldEntryIndex !== -1) {
      userViewsCount = (words[oldEntryIndex].userViews || 0) + 1;
      words.splice(oldEntryIndex, 1); // Remove the old entry
    }

      if (words.length >= WordsService.MAX_HISTORY) {
        // remove the oldest entry if MAX_HISTORY is exceeded
        words.pop(); // Use pop() to remove the last (oldest) item
      }

      const videoData = {
        id: video.id?.videoId || video.id || video.videoId,
        title: video.snippet?.title || video.title,
        channelTitle: video.snippet?.channelTitle || video.channelTitle,
        channelId: video.snippet?.channelId || video.channelId,
        description: video.snippet?.description || video.description,
        viewCount: video.viewCount || 0,
        publishDate: video.publishDate || "",
        lengthText: video.lengthText || formatTimeRP(parseInt(video.lengthSeconds)) || "",
        userViews: userViewsCount,
        thumbnail:
          video.snippet?.thumbnails?.medium?.url || video.thumbnail?.[3]?.url || video.thumbnail?.[0]?.url,
        sourceText: sourceText,
        sourceLanguage:sourceLang,
        targetText: targetText,
        targetLanguage: targetLang,
        sourceTileText: sourceTile.t,
        sourceTileIndex: sourceTile.i,
        targetTileText: targetTile.t
      }

      // Add the new entry to the history
      // history.push(videoData)
      words.unshift(videoData) //add to the front of the list, so most recent on top.

      await this.storage.set("recentwords", words)
  }

  async removeFromHistory(videoId) {
    let words = (await this.storage.get("recentwords")) || []

    words = words.filter((video) => video.id !== videoId)
    await this.storage.set("recentwords", words)
  }

  async getWords() {
    const words = await this.storage.get("recentwords")
    return words || []
  }

  async clearWords() {
    await this.storage.remove("recentwords")
  }
}

const wordsService = new WordsService()
export default wordsService
