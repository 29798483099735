/*
JMR
*/
import React, { useState, useEffect } from "react"
import { IonItem, IonLabel, IonThumbnail, IonText, IonRouterLink, } from "@ionic/react"
import HeartComponent from "../HeartComponent"
import favoriteVideosService from "../../utils/FavoriteVideosService"
import "../../App.scss"
import "./VideoCard.scss"
import { useHistory, useLocation } from "react-router-dom"

interface VideoProps {
  id: {
    videoId?: string
  }
  snippet?: {
    title?: string
    channelId?: string
    channelTitle?: string
    thumbnails?: {
      medium?: {
        url?: string
      }
    }
    description?: string
  }
  title?: string
  channelTitle?: string
  description?: string
  thumbnail?: string
  text?: string;
  channelId?: string
}

const VideoCardCompact = ({ video }: { video: VideoProps }) => {
  const videoId = video.id.videoId || video.id
  const title = video.snippet?.title || video.title
  const channelId = video.snippet?.channelId || video.channelId
  const channelTitle = video.snippet?.channelTitle || video.channelTitle
  const description = video.snippet?.description || video.description
  const thumbnailUrl = video.snippet?.thumbnails?.medium?.url || video.thumbnail

  const [shouldNavigate, setShouldNavigate] = useState(false)
  const [isFavorite, setIsFavorite] = useState(false)
  const isLocalVideo = channelTitle === "LOCAL";

  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    ;(async () => {
      const favorites = await favoriteVideosService.getFavorites()
      setIsFavorite(!!favorites.find((video) => video.id === videoId))
    })()
  }, [videoId, history, location])

  useEffect(() => {
    if (shouldNavigate) {
      history.push(isLocalVideo ? '/myvideo' : `/video/${videoId}`)
      setShouldNavigate(false) // Reset for future navigations
    }
  }, [shouldNavigate])

  const handleFavoriteClick = async () => {
    try {
      if (isFavorite) {
        await favoriteVideosService.removeFromFavorites(videoId)
        setIsFavorite(false)
      } else {
        await favoriteVideosService.addToFavorites(video)
        console.log("Sending to add fav:", video)
        setIsFavorite(true)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleNavigate = () => {
    //history.push(`/video/${videoId}`);
    setShouldNavigate(true)
  }

  return (
    <>
    <IonItem className="vcardCompact">
      <IonThumbnail slot="start" onClick={handleNavigate}>
        <img alt={title} src={thumbnailUrl} />
      </IonThumbnail>

      <IonLabel className="vcardCompact__labels">
        <h2 className="title" onClick={handleNavigate}>
          {title}
        </h2>
        
        
        {/* <IonRouterLink routerLink={`/channel/${channelId}`}>
          <h4 className="vsubtitle">{channelTitle}</h4>
        </IonRouterLink> */}

        {channelId ? (
            <IonRouterLink routerLink={isLocalVideo? '/myvideo' : `/channel/${channelId}`}>
                <h4 className="vsubtitle">{channelTitle}</h4>
            </IonRouterLink>
          ) : (
            <h4 className="vsubtitle">{channelTitle}</h4>
          )}



        <p className="description">{description}</p>
      </IonLabel>

      <IonLabel slot="end">
        <HeartComponent
          solid={isFavorite}
          onClick={handleFavoriteClick}
          size="large"
        />
      </IonLabel>
    </IonItem>

    {video.text && (
      <IonItem lines="none">
        <IonLabel>
          <IonText color="medium">
            <p className="notes-text">{video.text}</p>
          </IonText>
        </IonLabel>
      </IonItem>
    )}
</>
  )
}

export default VideoCardCompact

/*
    <IonGrid>
      <IonRow>
        <IonCol size="4">
          <IonRouterLink routerLink={`/video/${videoId}`}>
            <img alt={title} src={thumbnailUrl} className="video-thumb" />
          </IonRouterLink>
        </IonCol>
        <IonCol size="7">
          <IonRouterLink routerLink={`/video/${videoId}`}>
            <h2 className="title">{title}</h2>
            <p className="description">{description}</p>
          </IonRouterLink>
        </IonCol>
        <IonCol size="1">
          <HeartComponent solid={isFavorite} onClick={handleFavoriteClick} size="large" />
        </IonCol>
      </IonRow>
    </IonGrid>
*/
