import { Storage } from "@ionic/storage"

interface NoteVideo {
  text: string;
  videoData: {
    id: string;
    title: string;
    channelTitle: string;
    description: string;
    thumbnail: string;
    lengthText: string;
    publishDate: string;
    viewCount: string;
  };
}

export class NotesService {
  static MAX_NOTES = 500
  storage

  constructor() {
    this.storage = new Storage()
    this.storage.create()
  }

  async addToFavorites(video) {
    const favorites = (await this.storage.get("favorites")) || []

    if (favorites.length >= NotesService.MAX_NOTES) {
      throw new Error(
        "Maximum number of notes reached, please clear old notes"
      )
    }

    // Access the video data from the snippet and id objects in the payload
    const videoData = {
      id: video.id?.videoId || video.id || video.videoId,
      title: video.snippet?.title || video.title,
      channelTitle: video.snippet?.channelTitle || video.channelTitle,
      description: video.snippet?.description || video.description,
      lengthText : video.lengthText || "",
      publishDate : video.publishDate || "",
      viewCount : video.viewCount || "",
      thumbnail:
        video.snippet?.thumbnails?.medium?.url || video.thumbnail[0]?.url,
    }

    favorites.push(videoData)
    await this.storage.set("favorites", favorites)
  }

  async removeFromFavorites(videoId) {
    let favorites = (await this.storage.get("favorites")) || []

    favorites = favorites.filter((video) => video.id !== videoId)
    await this.storage.set("favorites", favorites)
  }

  async getFavorites() {
    const favorites = await this.storage.get("favorites")
    return favorites || []
  }

  //iterate through the note keys and retrieve the object values
  // async getNotes(): Promise<NoteVideo[]> {
  //   const notes: NoteVideo[] = [];
  //   await this.storage.forEach((value: NoteVideo, key: string) => {
  //     if (key.startsWith('note_')) {
  //       notes.push(value);


  //       //Examine if the note structure is correct, if it is not then correct it
  //       //if note content has no keys then set the text key to the entire value
  //       //if note content does not have videoData key, then extract the videoId, create the structure and set the value for the id key to the extracted value, thumbnail to /assets/generic.png, and all other fields are blank
  //     }
  //   });
  
  //   return notes || [];
  // }
  
  async getNotes(): Promise<NoteVideo[]> {
    const notes: NoteVideo[] = [];
    await this.storage.forEach((value: any, key: string) => {
      if (key.startsWith('note_')) {
        let compliantNote: NoteVideo;
  
        // Check if the value is not in the expected format and fix the structure
        if (typeof value === 'string' || !value.text || !value.videoData) {
          const videoId = key.substring(5); // extract videoId from key
          compliantNote = {
            text: typeof value === 'string' ? value : value.text || "",
            videoData: value.videoData || {
              id: videoId,
              title: "< PLEASE OPEN VIDEO AND SAVE To REPAIR >",
              channelTitle: "",
              description: "",
              thumbnail: "/assets/generic.png",
              lengthText: "",
              publishDate: "",
              viewCount: "",

            },
          };
          this.storage.set(key, compliantNote); //upgrade old notes to new format
        } else {
          compliantNote = value; // value is already in the correct format
        }
  
        notes.push(compliantNote);
      }
    });
  
    return notes;
  }

  


  async updateFavorite(videoId, updatedData) {
    let favorites = (await this.storage.get("favorites")) || []

    const videoIndex = favorites.findIndex((video) => video.id === videoId)

    if (videoIndex !== -1) {
      favorites[videoIndex] = { ...favorites[videoIndex], ...updatedData }
      await this.storage.set("favorites", favorites)
    }
  }
}

const notesService = new NotesService()
export default notesService
