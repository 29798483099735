import React, { useEffect } from 'react';
import './SplashScreen.scss';

const SplashScreen: React.FC<{ onEnd: () => void, isVisible: boolean }> = ({ onEnd, isVisible }) => {
  /*useEffect(() => {
    const timer = setTimeout(onEnd, 3000); // Extended time for all animations
    return () => clearTimeout(timer);
  }, [onEnd]); 
  */

  useEffect(() => {
    if (isVisible) {
      const timer = setTimeout(onEnd, 3000); // Extended time for all animations
      return () => clearTimeout(timer);
    }
  }, [onEnd, isVisible]);

  if (!isVisible) return null;

  return (
    <div className="splash-container">
      <video autoPlay playsInline loop muted>
        <source src="/intro2.mp4" type="video/mp4" />
      </video>
      <div className="content-with-gradient">

        <div className="splash-text">Space Subs</div>
        <div className="grid">
            <div className="box"></div>
            <div className="box"></div>
            <div className="box"></div>
            <div className="box"></div>
            <div className="box"></div>
            <div className="box"></div>
        </div>
        </div>
        </div>
  );
};

export default SplashScreen;
