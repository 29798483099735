import React, { useState, useEffect } from "react";
import {
  IonRouterLink,
  IonAvatar,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonModal,
  IonButton,
  IonContent,
  IonIcon,
  IonToast,
} from "@ionic/react";

import {
  addCircle,
  removeCircle,
} from "ionicons/icons"

import { Storage } from '@ionic/storage';

const storage = new Storage();
storage.create();



const ChannelCard = ({ channelDetail }) => {
  // State for modal visibility
  const [showModal, setShowModal] = useState(false);
  const [isChannelInList, setIsChannelInList] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');



  useEffect(() => {
    const checkChannelInList = async () => {
      const channels = await storage.get('channels') || [];
      const isChannelSaved = channels.some(channel => channel.channelId === channelDetail?.channelId);
      setIsChannelInList(isChannelSaved);
    };

    checkChannelInList();
  }, [channelDetail]);

  const handleChannelSave = async () => {
    const channels = await storage.get('channels') || [];
    if (isChannelInList) {
      const updatedChannels = channels.filter(channel => channel.channelId !== channelDetail?.channelId);
      await storage.set('channels', updatedChannels);
      setIsChannelInList(false);
      setToastMessage('Channel removed from your channels list');
    } else {
      // await storage.set('channels', [...channels, channelDetail]);
      // Prepend channelDetail to the front of the channels list
      await storage.set('channels', [channelDetail, ...channels]);

      setIsChannelInList(true);
      setToastMessage('Channel added to your channels list');
    }
    setShowToast(true);
  };

  // Using the last banner for the highest resolution
  const bannerUrl = channelDetail?.banner?.[channelDetail.banner.length - 1]?.url;
  // Function to truncate and format description
  const formatDescription = (description) => {
    if (description && description.length > 33) {
      return `${description.substring(0, 33)}...`;
    }
    return description;
  };



  return (
    <>
    <IonRouterLink routerLink={`/channel/${channelDetail?.channelId}`}>
      <IonCard style={{ border: '1px solid darkgrey' }}>
        {bannerUrl && (
          <img
            src={bannerUrl}
            alt={`Banner for ${channelDetail?.title}`}
            style={{ width: '100%', height: 'auto' }}
          />
        )}

        <IonCardHeader>
          <IonIcon
                size="large"
                  icon={isChannelInList ? removeCircle : addCircle}
                  onClick={handleChannelSave}
                  style={{ fontSize: '25px', cursor: 'pointer', float: 'right' }}
                  color={isChannelInList ? "warning" : "danger"}
                />
          <div style={{ display: 'flex', alignItems: 'center' }}>
          <IonAvatar style={{ marginRight: '15px', width: '30%', height: '30%', flexShrink: 0 }}>
              <img
                alt={channelDetail?.title}
                src={channelDetail?.avatar?.[channelDetail.avatar.length - 1]?.url}
                style={{ width: '100%', height: '100%', objectFit: 'cover', maxWidth: '150px', maxHeight: '150px' }}
              />
            </IonAvatar>
            <div>
              <IonCardTitle style={{ textTransform: 'none' }}>{channelDetail?.title}</IonCardTitle>
              <IonCardSubtitle style={{ textTransform: 'none' }}>{channelDetail?.channelHandle}</IonCardSubtitle>
              <IonCardSubtitle style={{ textTransform: 'none' }}>{channelDetail?.subscriberCountText} subscribers</IonCardSubtitle>
              <IonCardSubtitle style={{ textTransform: 'none' }}>{channelDetail?.videosCountText} videos</IonCardSubtitle>
                            <p onClick={() => setShowModal(true)} style={{ color: 'skyblue', cursor: 'pointer', marginTop: '10px' }}>
                {formatDescription(channelDetail?.description) + " >"}
              </p>
              



              <IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
                <IonContent className='ion-padding'>
                  <p>{channelDetail?.description}</p>
                  <IonButton onClick={() => setShowModal(false)}>Close</IonButton>
                </IonContent>
              </IonModal>

            </div>
          </div>
        </IonCardHeader>
      </IonCard>
    </IonRouterLink>
          <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message={toastMessage}
          duration={2000}
          position="middle"
        />
      </>
  );
};

export default ChannelCard;





/* OLD:
import React from "react"
import {
  IonRouterLink,
  IonAvatar,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
} from "@ionic/react"

const ChannelCard = ({ channelDetail }) => {
  console.log("[ChannelCard]: ")
  console.log(channelDetail)
  return (
    <IonRouterLink routerLink={`/channel/${channelDetail?.id?.channelId}`}>
      <IonCard>
        <IonAvatar>
          <img
            alt={channelDetail?.snippet?.title}
            src={channelDetail?.snippet?.thumbnails?.high?.url}
          />
        </IonAvatar>

        <IonCardHeader>
          <IonCardTitle>{channelDetail?.snippet?.title}</IonCardTitle>
          <IonCardSubtitle>
            {channelDetail?.snippet?.description}
          </IonCardSubtitle>
        </IonCardHeader>

        <IonCardContent>(content area)</IonCardContent>
      </IonCard>
    </IonRouterLink>
  )
}

export default ChannelCard
*/