import React, { useState, useEffect, useRef } from 'react';
import { IonContent, IonPage, IonButton, IonTextarea, IonSelect, IonSelectOption, IonInput } from '@ionic/react';

// Assuming main.js and helpers.js export functions you need
// import * as Main from './main';
// import * as Helpers from './helpers';

const TranscribePage = () => {
  const [transcription, setTranscription] = useState('');
  const [language, setLanguage] = useState('en');
  const [threads, setThreads] = useState(8);
  const [audioFile, setAudioFile] = useState(null);
  const [modelStatus, setModelStatus] = useState('');
  const audioContextRef = useRef(null);

  const kMaxAudio_s = 30 * 60;
  const kSampleRate = 16000;

  useEffect(() => {
    window.AudioContext = window.AudioContext || window.webkitAudioContext;
    window.OfflineAudioContext = window.OfflineAudioContext || window.webkitOfflineAudioContext;

    if (!audioContextRef.current) {
      audioContextRef.current = new AudioContext({
        sampleRate: kSampleRate,
        channelCount: 1,
        echoCancellation: false,
        autoGainControl: true,
        noiseSuppression: true,
      });
    }

    // Initialize model here using Main or Helpers if needed
    // Main.initModel().then(() => {
    //   setModelStatus('Model loaded');
    // }).catch((error) => {
    //   console.error('Model loading error:', error);
    //   setModelStatus('Error loading model');
    // });
  }, []);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) {
      console.log('No file selected');
      return;
    }
    setAudioFile(file);

    console.log(`js: loading audio: ${file.name}, size: ${file.size} bytes`);
    console.log('js: please wait ...');

    const reader = new FileReader();
    reader.onload = () => {
      const buf = new Uint8Array(reader.result);

      audioContextRef.current.decodeAudioData(buf.buffer, (audioBuffer) => {
        const offlineContext = new OfflineAudioContext(audioBuffer.numberOfChannels, audioBuffer.length, audioBuffer.sampleRate);
        const source = offlineContext.createBufferSource();
        source.buffer = audioBuffer;
        source.connect(offlineContext.destination);
        source.start(0);

        offlineContext.startRendering().then((renderedBuffer) => {
          const audioData = renderedBuffer.getChannelData(0);
          console.log(`js: audio loaded, size: ${audioData.length}`);

          // Action after loading audio data
          // Possibly set state or prepare for transcription
          // This is just logging for now
          if (audioData.length > kMaxAudio_s * kSampleRate) {
            console.log(`js: truncated audio to first ${kMaxAudio_s} seconds`);
          }
        });
      }, (e) => {
        console.log(`js: error decoding audio: ${e}`);
      });
    };
    reader.readAsArrayBuffer(file);
  };

  const onTranscribe = async () => {
    if (!audioFile) {
      alert('Please select an audio file first.');
      return;
    }

    // Transcription logic using Helpers
    // Helpers.transcribeAudio(audioFile, language, threads)
    //   .then(response => setTranscription(response))
    //   .catch(error => console.error('Transcription error:', error));
  };

  return (
    <IonPage>
      <IonContent className="ion-padding">
        <div>
          <input type="file" onChange={handleFileChange} />
          <IonButton onClick={onTranscribe}>Transcribe</IonButton>
          <IonTextarea value={transcription} readonly></IonTextarea>
        </div>
        {/* Additional UI elements as needed */}
      </IonContent>
    </IonPage>
  );
};

export default TranscribePage;
