import React, { useState } from "react"
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
} from "@ionic/react"

import { useSwipeable } from "react-swipeable"

import "./ExploreContainer.scss"

interface ExploreContainerProps {
  videos: string[]
}

const slideOpts = {
  initialSlide: 0,
  speed: 400,
}

const ExploreContainer: React.FC<ExploreContainerProps> = ({ videos }) => {
  const [currentIndex, setCurrentIndex] = useState(0)

  const handleSwipe = (direction: "left" | "right") => {
    if (direction === "left" && currentIndex < videos.length - 1) {
      setCurrentIndex((prev) => prev + 1)
    } else if (direction === "right" && currentIndex > 0) {
      setCurrentIndex((prev) => prev - 1)
    }
  }

  const handlers = useSwipeable({
    onSwipedLeft: () => handleSwipe("left"),
    onSwipedRight: () => handleSwipe("right"),
    trackMouse: true,
  })

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Explore</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className="compact-list-padding">
        <div className="explainer-container" {...handlers}>
          <div className="video-wrapper">
            <video autoPlay loop muted playsInline src={videos[currentIndex]} />
          </div>
          <div className="dot-indicators">
            {videos.map((_, index) => (
              <div
                key={index}
                className={`dot ${currentIndex === index ? "active" : ""}`}
              ></div>
            ))}
          </div>
        </div>
      </IonContent>
    </IonPage>
  )
}

export default ExploreContainer

/* Manual swiping, with no animation
  const handleSwipe = (direction: 'left' | 'right') => {
    if (direction === 'left' && currentIndex < videos.length - 1) {
      setCurrentIndex(prev => prev + 1);
    } else if (direction === 'right' && currentIndex > 0) {
      setCurrentIndex(prev => prev - 1);
    }
  };


  const handlers = useSwipeable({
    onSwipedLeft: () => handleSwipe('left'),
    onSwipedRight: () => handleSwipe('right'),
    trackMouse: true
  });
  */

/*
  const handleSlideChange = (event: CustomEvent) => {
    console.log(event.detail); // Let's see the output
    if (event && event.detail && typeof event.detail.index !== 'undefined') {
      setCurrentIndex(event.detail.index);
    } else {
      console.error("Couldn't extract index from slide change event.");
    }
  };
*/

/* OLD Working slides, but no animation
    <IonPage>
    <IonHeader>
      <IonToolbar>
        <IonTitle>Explore</IonTitle>
      </IonToolbar>
    </IonHeader>
    <IonContent fullscreen className="compact-list-padding">
    <div className="explainer-container" {...handlers}>
    <div className="video-wrapper">
        <video autoPlay loop muted src={videos[currentIndex]} />
      </div>
      <div className="dot-indicators">
        {videos.map((_, index) => (
          <div
            key={index}
            className={`dot ${currentIndex === index ? 'active' : ''}`}
          ></div>
        ))}
      </div>
    </div>

    </IonContent>
  </IonPage>

  */

/* :
import "./ExploreContainer.css"

interface ContainerProps {
  name: string
}

const ExploreContainer: React.FC<ContainerProps> = ({ name }) => {
  return (
    <div className="exploreContainer">
      <strong>{name}</strong>
      <p>
        Explore
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.braindojang.com"
        >
          Learn Stuff at Brain Dojo
        </a>
      </p>
    </div>
  )
}

export default ExploreContainer
*/
