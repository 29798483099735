// import React from "react"
import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { IonList } from "@ionic/react"
import VideoCardMobile from "./VideoCardMobile"
import VideoCardTablet from "./VideoCardTablet"
import { useMediaQuery } from "react-responsive"
import "./VideoCard.scss"
import { useSearch } from '../../SearchContext'; // Import your useSearch hook



const Videos = ({ videos }) => {

  const [prevLength, setPrevLength] = useState(0);
  const videoRefs = useRef<(HTMLDivElement | null)[]>(new Array(videos.length).fill(null));
  const [firstRun, setFirstRun] = useState(true);
  const { lastClickedIndex, updateLastClickedIndex } = useSearch();


  // First useEffect: Assign refs to each video item
  useEffect(() => {
    videos.forEach((video, index) => {
      const element = document.getElementById(`video-${video.videoId}`);
      if (element) {
        videoRefs.current[index] = element as HTMLDivElement;
      }
    });
  }, [videos]);

  // Second useEffect: Handle the scroll behavior
  useEffect(() => {
    // Capture the current length before updating
    if (firstRun && videos.length>0) {
      setFirstRun(false);
      console.log("VIDEOS First Run DONE !!!!!!!");
    } else{
      // const targetIndex = prevLength -1;
      const targetIndex = lastClickedIndex !== -1 ? lastClickedIndex : prevLength - 1;

      const timer = setTimeout(() => {
        // const targetIndex = currentLength - prevLength - 1;
        // Additional check for null
        if (videoRefs.current[targetIndex] !== null) {
          videoRefs.current[targetIndex]?.scrollIntoView({ behavior: "smooth", block: "start" });
          console.log("SCROLLING TO INDEX: "+ targetIndex);
            // Update prevLength after the timeout
          setPrevLength(videos.length);
          updateLastClickedIndex(-1);
        }
      }, 300);
    return () => clearTimeout(timer);
  }
  }, [videos]);

  // useEffect(() => {
  //   const scrollIntoView = () => {
  //     const targetIndex = lastClickedIndex !== -1 ? lastClickedIndex : prevLength - 1;
  //     const targetElement = videoRefs.current[targetIndex];
  
  //     if (targetElement && targetElement.offsetParent !== null) {
  //       console.log(">>>>>>>>> SCROLLING to Video Index: " + targetIndex);
  //       targetElement.scrollIntoView({ behavior: "smooth", block: "start" });
  //       setPrevLength(videos.length);
  //       updateLastClickedIndex(-1);
  //     }
  //   };
  
  //   if (firstRun && videos.length > 0) {
  //     setFirstRun(false);
  //     console.log(">>>>>>>>> VIDEOS First Run DONE !!!!!!!");
  //   } else if (!firstRun || lastClickedIndex !== -1) {
  //     const timer = setTimeout(scrollIntoView, 300);
  //     return () => clearTimeout(timer);
  //   }
  // }, [videos, lastClickedIndex]);
  

  // Helper function to convert HH:MM:SS to seconds
  const convertToSeconds = (time) => {
    if (!time || time === "LIVE" || time === "live") {
      return -1 // Return -1 for 'LIVE' or 'live' so we can easily filter it out later
    }

    const segments = time.split(":").map(Number)

    // Check the number of segments to determine format
    switch (segments.length) {
      case 1: // Format: SS
        return segments[0]
      case 2: // Format: MM:SS
        return segments[0] * 60 + segments[1]
      case 3: // Format: HH:MM:SS
        return segments[0] * 3600 + segments[1] * 60 + segments[2]
      default:
        return 0 // Default value
    }
  }

  //Determine size of device
  const isTabletOrDesktop = useMediaQuery({ query: "(min-width: 768px)" })

  //Filter out live videos and anything that's not a standard video like shorts
  const maxDuration = 3 * 60 * 60 // 3 hours in seconds

  const filteredVideos = videos.filter((item) => {
    const videoDuration = convertToSeconds(item.lengthText)
    return (
      item.type === "video" &&
      videoDuration !== -1 &&
      videoDuration < maxDuration
    )
  })

  const cacheChannelInfo = (idx) => {
    updateLastClickedIndex(idx);
    
  }


  console.log("MAPPING THRU VIDEO LISTING........")
  console.log(videos)

  return (
    <IonList className="videoList">
      {filteredVideos.map((item, idx) => (
                <div
                key={item.videoId}
                id={`video-${item.videoId}`} // Assign an ID to each video element
                ref={el => videoRefs.current[idx] = el} // Assign ref
                onClick={() => cacheChannelInfo(idx)}
              >
  
          {/*item.id.videoId && <VideoCard video={item} /> */}
          {/*item.id.channelId && <ChannelCard channelDetail={item} /> */}

          {/*isTabletOrDesktop ?
                    item.id.videoId && <VideoCardTablet key={`${item.id.videoId}-${item.isFavorite}`} video={item} /> 
                    :
                    item.id.videoId && <VideoCardMobile key={`${item.id.videoId}-${item.isFavorite}`} video={item} /> 
                */}
          {/*item.id.channelId && <ChannelCard channelDetail={item} />*/}

            {isTabletOrDesktop
              ? item.videoId &&
                item.lengthText && (
                  <VideoCardTablet
                    key={`${item.videoId}-${item.isFavorite}`}
                    video={item}
                  />
                )
              : item.videoId &&
                item.lengthText && (
                  <VideoCardMobile
                    key={`${item.videoId}-${item.isFavorite}`}
                    video={item}
                  />
                )}


          {/* In the old YT API, channelId was only sent when viewing the channel page, now it's always sent, so 
                need another way to conditionally show the channelcard or move to it's own compeonent/route */}
          {/*item.channelId && <ChannelCard channelDetail={item} />*/}
       
        </div>
      ))}
    </IonList>
  )





}

export default Videos
