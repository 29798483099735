/*
JMR
*/
import React, { useState, useEffect } from "react"
import { IonItem, IonLabel, IonThumbnail, IonText, IonRouterLink, IonCard, IonCardTitle, IonCardSubtitle,IonCardContent } from "@ionic/react"
import HeartComponent from "../HeartComponent"
import favoriteVideosService from "../../utils/FavoriteVideosService"
import "../../App.scss"
import "./VideoCardWord.scss"
import { useHistory, useLocation } from "react-router-dom"

interface VideoProps {
  id: {
    videoId?: string
  }
  snippet?: {
    title?: string
    channelId?: string
    channelTitle?: string
    thumbnails?: {
      medium?: {
        url?: string
      }
    }
    description?: string
  }
  title?: string
  channelTitle?: string
  description?: string
  thumbnail?: string
  text?: string;
  channelId?: string
  sourceLanguage?: string
  sourceText?: string
  sourceTile?: string
  sourceTileIndex?: number
  targetLanguage?: string
  targetText?: string
  targetTile?: string
}

const VideoCardCompactWord = ({ video }: { video: VideoProps }) => {
  const videoId = video.id.videoId || video.id
  const title = video.snippet?.title || video.title
  const channelId = video.snippet?.channelId || video.channelId
  const channelTitle = video.snippet?.channelTitle || video.channelTitle
  const description = video.snippet?.description || video.description
  const thumbnailUrl = video.snippet?.thumbnails?.medium?.url || video.thumbnail
console.log("VideoCardCompactWord: ",video)
  const [shouldNavigate, setShouldNavigate] = useState(false)
  const [isFavorite, setIsFavorite] = useState(false)
  const isLocalVideo = channelTitle === "LOCAL";

  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    ;(async () => {
      const favorites = await favoriteVideosService.getFavorites()
      setIsFavorite(!!favorites.find((video) => video.id === videoId))
    })()
  }, [videoId, history, location])

  useEffect(() => {
    if (shouldNavigate) {
      history.push(isLocalVideo ? '/myvideo' : `/video/${videoId}`)
      setShouldNavigate(false) // Reset for future navigations
    }
  }, [shouldNavigate])

  const handleFavoriteClick = async () => {
    try {
      if (isFavorite) {
        await favoriteVideosService.removeFromFavorites(videoId)
        setIsFavorite(false)
      } else {
        await favoriteVideosService.addToFavorites(video)
        console.log("Sending to add fav:", video)
        setIsFavorite(true)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleNavigate = () => {
    //history.push(`/video/${videoId}`);
    setShouldNavigate(true)
  }

  return (
    <IonCard className="earthy-gradient">
    <IonCardContent>
      {video.sourceLanguage && video.sourceText && video.targetLanguage && video.targetText && (
        <div className="language-section">
          <IonCardSubtitle className="large-subtitle-source">
            [{video.sourceLanguage}]: {video.sourceText}
          </IonCardSubtitle>
          <IonCardSubtitle className="large-subtitle-target">
            [{video.targetLanguage}]: {video.targetText}
          </IonCardSubtitle>
        </div>
      )}
      <div className="video-section">
        <IonThumbnail slot="start" onClick={handleNavigate}>
          <img alt={title} src={thumbnailUrl} />
        </IonThumbnail>

        <IonLabel className="vcardCompact__labels">
          <h2 className="title" onClick={handleNavigate}>
            {title}
          </h2>

          {channelId ? (
            <IonRouterLink routerLink={isLocalVideo ? '/myvideo' : `/channel/${channelId}`}>
              <h4 className="vsubtitle">{channelTitle}</h4>
            </IonRouterLink>
          ) : (
            <h4 className="vsubtitle">{channelTitle}</h4>
          )}
        </IonLabel>

        {/*
        <IonLabel slot="end">
          <HeartComponent
            solid={isFavorite}
            onClick={handleFavoriteClick}
            size="large"
            className="heart-icon"
          />
        </IonLabel>
        */}
      </div>

      {video.text && (
        <div className="notes-section">
          <IonLabel>
            <IonText color="medium">
              <p className="notes-text">{video.text}</p>
            </IonText>
          </IonLabel>
        </div>
      )}
    </IonCardContent>
  </IonCard>
  )
}

export default VideoCardCompactWord