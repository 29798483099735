/*
JMR
*/
import { IonIcon } from "@ionic/react"
import { heartOutline, heartSharp } from "ionicons/icons"
/*
interface HeartComponentProps {
    solid: boolean; 
    onClick: () => void;
    size?: 'small' | 'medium' | 'large';
}*/
interface HeartComponentProps {
  solid: boolean
  onClick: (event: React.MouseEvent) => void // change this line
  size?: "small" | "medium" | "large"
  className?: string
}

const HeartComponent: React.FC<HeartComponentProps> = ({
  solid,
  onClick,
  size,
  className,
}) => {
  // map size to actual values
  const sizeMapping = {
    small: "20px",
    medium: "24px",
    large: "28px",
  }

  const actualSize = size ? sizeMapping[size] : sizeMapping["medium"] // Default to 'medium' if size is not provided

  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation()
    onClick(event)
  }

  return (
    <IonIcon
      icon={solid ? heartSharp : heartOutline}
      onClick={handleClick}
      style={{
        cursor: "pointer",
        fontSize: actualSize,
        color: solid ? "red" : "grey",
      }}
      className={className}
    />
  )
}

export default HeartComponent

/*import { IonIcon } from '@ionic/react';
import { heartOutline, heartSharp } from 'ionicons/icons';

const HeartComponent: React.FC<{ solid: boolean; onClick: () => void }> = ({ solid, onClick }) => {
    return (
        <IonIcon icon={solid ? heartSharp : heartOutline} onClick={onClick} style={{ cursor: 'pointer' }}/>
    );
};

export default HeartComponent;



return (
        <IonIcon 
            icon={solid ? heartSharp : heartOutline} 
            onClick={onClick} 
            style={{ cursor: 'pointer', fontSize: actualSize, color: solid ? 'red' : 'grey' }}

        />
    );
*/
