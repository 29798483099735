import React, { useState } from "react"
import ReactPlayer from "react-player"

interface SrtSegment {
  start: number
  end: number
  text: string
}
/*
interface Props {
  srtFile: File;
}

const SrtPlayer: React.FC<Props> = ({ srtFile }) => {
  */
const SrtPlayer = () => {
  const [segments, setSegments] = useState<SrtSegment[]>([])
  const [url, setUrl] = useState("https://www.youtube.com/watch?v=MUOVnIbTZeA")
  const [playing, setPlaying] = useState<boolean>(false)
  const [playedSeconds, setPlayedSeconds] = useState<number>(0)

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (file) {
      //setUrl(URL.createObjectURL(file));
      readSrtFile(file)
    }
  }

  const readSrtFile = (file: File) => {
    const reader = new FileReader()
    reader.onload = () => {
      const srtContent = reader.result as string
      const srtSegments = parseSrt(srtContent)
      setSegments(srtSegments)
    }
    reader.readAsText(file)
  }

  const parseSrt = (srtContent: string): SrtSegment[] => {
    const lines = srtContent.trim().split("\n")
    const segments: SrtSegment[] = []
    for (let i = 0; i < lines.length; i += 4) {
      const parts = lines[i + 1].split(" --> ")
      const start = parseTime(parts[0])
      const end = parseTime(parts[1])
      const text = lines[i + 2].trim()
      segments.push({ start, end, text })
    }
    return segments
  }

  const parseTime = (timeString: string): number => {
    //const parts = timeString.split(' --> ');
    const startParts = timeString.split(":").map(parseFloat)
    //const endParts = timeString.split(':').map(parseFloat);
    const startTime = startParts[0] * 3600 + startParts[1] * 60 + startParts[2]
    //const endTime = endParts[0] * 3600 + endParts[1] * 60 + endParts[2];
    return startTime
  }

  const handlePlay = (segment: SrtSegment) => {
    setPlaying(true)
    setPlayedSeconds(segment.start)
    console.log("Clicked button to play video: " + url)
  }

  const handleProgress = (state: any) => {
    setPlayedSeconds(state.playedSeconds)
  }

  return (
    <>
      <input type="file" onChange={handleFileChange} />
      {segments.map((segment, index) => (
        <button key={index} onClick={() => handlePlay(segment)}>
          {segment.start} : {segment.end} <br /> {segment.text}
        </button>
      ))}
      {url && (
        <ReactPlayer
          url={url}
          playing={playing}
          onProgress={handleProgress}
          progressInterval={100}
          onEnded={() => setPlaying(false)}
          playedSeconds={playedSeconds}
        />
      )}
    </>
  )
}

export default SrtPlayer
