import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom"
import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonButton,
  IonSelect, IonSelectOption, IonAlert
} from "@ionic/react"
import {
  homeOutline,
  documentOutline,
  timeOutline,
  albumsOutline,
  archiveOutline,
  bookmarkOutline,
  heartOutline,
  trashOutline,
  searchOutline,
  layersOutline,
  happyOutline,
  playCircleOutline,
  listOutline,
  globeOutline
} from "ionicons/icons"
import "./Menu.scss"
import { Storage } from "@ionic/storage"
import { UserAuth } from '../../auth'
import { useHistory } from "react-router-dom"


interface AppPage {
  url: string
  iosIcon: string
  mdIcon: string
  title: string
}

const appPages: AppPage[] = [
  {
    title: "Dashboard",
    url: "/dash",
    iosIcon: homeOutline,
    mdIcon: homeOutline,
  },
  {
    title: "Search",
    url: "/home/new",
    iosIcon: searchOutline,
    mdIcon: searchOutline,
  },
  {
    title: "Todo",
    url: "/todopage",
    iosIcon: listOutline,
    mdIcon: listOutline,
  },
  {
    title: "Vocabulary",
    url: "/wordspage",
    iosIcon: globeOutline,
    mdIcon: globeOutline,
  },
  {
    title: "Welcome",
    url: "/welcome",
    iosIcon: happyOutline,
    mdIcon: happyOutline,
  },
  {
    title: "Favorites",
    url: "/favorites",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
  },
  {
    title: "History",
    url: "/myhistory",
    iosIcon: layersOutline,
    mdIcon: layersOutline,
  },
  {
    title: "Flashcards",
    url: "/comingsoon",
    iosIcon: timeOutline,
    mdIcon: timeOutline,
  },
  {
    title: "Notes",
    url: "/mynotes",
    iosIcon: documentOutline,
    mdIcon: documentOutline,
  },
  {
    title: "Local Player",
    url: "/myvideo",
    iosIcon: playCircleOutline,
    mdIcon: playCircleOutline,
  },
  {
    title: "Explainer",
    url: "/explainer",
    iosIcon: albumsOutline,
    mdIcon: albumsOutline,
  },
]

const labels = [
  "Greetings",
  "Ordering Food",
  "Vocabulary",
  "Work Terms",
  "Mandarin",
  "Korean",
]

const Menu: React.FC = () => {

  // const [selectedLanguageCode, setSelectedLanguageCode] = useState<string>('en'); // Default to English
  // const [nativeLanguageCode, setNativeLanguageCode] = useState<string>('en'); // Default to English 
  const history = useHistory()
  const {user, logout, selectedLanguageCode, nativeLanguageCode, updateLanguageSetting} = UserAuth();
  const [showLogoutAlert, setShowLogoutAlert] = useState(false);
  const location = useLocation()
  const [showClearSubsAlert, setShowClearSubsAlert] = useState(false);


  // Create an instance of Ionic Storage
  const storage = new Storage()
  storage.create()

  const languageOptions = {
    'en' : 'English',
    'zh': 'Mandarin',
    'zh-Hant': 'Mandarin (Traditional)',
    'ko': 'Korean',
    'ja': 'Japanese',
    'fr': 'French',
    'de': 'German',
    'it': 'Italian',
    'id': 'Indonesian',
    'pt': 'Portuguese',
    'es': 'Spanish',
    'th': 'Thai'
  };

  // useEffect(() => {
  //   // Load the stored language code on component mount
  //   const loadStoredLanguage = async () => {
  //     const storedLanguage = await storage.get('selectedLanguageCode');
  //     const storedNativeLanguage = await storage.get('nativeLanguageCode');
 
  //     if (storedLanguage) {
  //       setSelectedLanguageCode(storedLanguage);
  //     }else{
  //       await storage.set('selectedLanguageCode', 'en');
  //     }

  //     if (storedNativeLanguage) {
  //       setNativeLanguageCode(storedNativeLanguage);
  //     } else {
  //       await storage.set('nativeLanguageCode', 'en');
  //     }
  //   };
  //   loadStoredLanguage();
  // }, []);

  // const handleLanguageChange = async (languageCode: string) => {
  //   setSelectedLanguageCode(languageCode);
  //   await storage.set('selectedLanguageCode', languageCode);
  // };

  // const handleNativeLanguageChange = async (languageCode: string) => {
  //   setNativeLanguageCode(languageCode);
  //   await storage.set('nativeLanguageCode', languageCode);
  // };


  const handleLanguageChange = (value) => {
    updateLanguageSetting('selectedLanguageCode', value);
};

  const handleNativeLanguageChange = (value) => {
      updateLanguageSetting('nativeLanguageCode', value);
  };

  const handleLogout = async () => {
    setShowLogoutAlert(false); 
    try {
      // setStatus({ loading: true, error: false });
      await logout();
      console.log('Logged Out!');
      history.push(`/login`)
    } catch (error) {
      // setStatus({ loading: false, error: true });
      console.log('error:', error);
    }
  };

  // const clearSubtitles = async () => {
  //   const keys: string[] = await storage.keys();
  //   const subtitleKeys = keys.filter(key => key.startsWith('subtitles_'));
  //   for (const key of subtitleKeys) {
  //     await storage.remove(key);
  //   }
  // }
  const clearSubtitles = async () => {
    setShowClearSubsAlert(false); // Close the alert dialog
    const keys: string[] = await storage.keys();
    const subtitleKeys = keys.filter(key => key.startsWith('subtitles_'));
    for (const key of subtitleKeys) {
      await storage.remove(key);
    }
};


  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonList id="inbox-list">
          <IonListHeader>Space Subs</IonListHeader>

          <div style={{ padding: '5px 16px' }}> {/* Adjust padding as needed */}
            <p style={{ margin: 0, fontSize: '1rem', color: 'var(--ion-color-primary)' }}>
              {user && user.email || 'Happy Learner'}
            </p>

            <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginTop: '5px' }}>

              <label htmlFor="native-language-select" style={{ fontSize: '.8rem', marginBottom: 0 }}>
                Native Language:
              </label>
              <IonSelect value={nativeLanguageCode} onIonChange={e => handleNativeLanguageChange(e.detail.value)}>
                {Object.entries(languageOptions).map(([code, language]) => (
                  <IonSelectOption key={code} value={code}>{language}</IonSelectOption>
                ))}
              </IonSelect>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginTop: '5px' }}>

              <label htmlFor="language-select" style={{ fontSize: '.8rem', marginBottom: 0 }}>
                Target Language:
              </label>
              <IonSelect value={selectedLanguageCode} onIonChange={e => handleLanguageChange(e.detail.value)}>
                {Object.entries(languageOptions).map(([code, language]) => (
                  <IonSelectOption key={code} value={code}>{language}</IonSelectOption>
                ))}
              </IonSelect>
          </div>

          </div>
          <br/>

          {appPages.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem
                  className={
                    location.pathname === appPage.url ? "selected" : ""
                  }
                  routerLink={appPage.url}
                  routerDirection="forward"
                  lines="none"
                  detail={false}
                >
                  <IonIcon
                    aria-hidden="true"
                    slot="start"
                    ios={appPage.iosIcon}
                    md={appPage.mdIcon}
                  />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            )
          })}
        </IonList>

{/*  ADD Later when functionality is done
        <IonList id="labels-list">
          <IonListHeader>Labels</IonListHeader>
          {labels.map((label, index) => (
            <IonItem lines="none" key={index}>
              <IonIcon aria-hidden="true" slot="start" icon={bookmarkOutline} />
              <IonLabel>{label}</IonLabel>
            </IonItem>
          ))}
        </IonList>
*/}

        <IonItem>
          <IonButton color="medium" size="small" onClick={() => setShowLogoutAlert(true)}>
              <IonIcon slot="start" />
              Logout
          </IonButton>
        </IonItem>
        <IonItem>
          <IonButton color="warning" size="small" onClick={() => setShowClearSubsAlert(true)}>
            <IonIcon icon={trashOutline} slot="start" />
            Clear Subs (caution)
          </IonButton>
        </IonItem>    
      </IonContent>

      <IonAlert
        isOpen={showLogoutAlert}
        onDidDismiss={() => setShowLogoutAlert(false)}
        header={'Confirm Logout'}
        message={'Are you sure you want to log out?'}
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              setShowLogoutAlert(false);
            }
          },
          {
            text: 'Yes, Logout',
            handler: () => {
              handleLogout();
            }
          }
        ]}
      />

      <IonAlert
          isOpen={showClearSubsAlert}
          onDidDismiss={() => setShowClearSubsAlert(false)}
          header={'Confirm Clear'}
          message={'Are you sure you want to clear all your subtitles data?'}
          buttons={[
            {
              text: 'Cancel',
              role: 'cancel',
              cssClass: 'secondary',
              handler: blah => {
                setShowClearSubsAlert(false);
              }
            },
            {
              text: 'Yes, Clear',
              handler: () => {
                clearSubtitles();
              }
            }
          ]}
      />

    </IonMenu>
  )
}

export default Menu
