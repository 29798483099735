// SubtitleEditor.tsx
import React, { useState, useEffect } from "react"
import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButton,
  IonTextarea,
  IonLabel,
} from "@ionic/react"
import "./SubtitleEditor.scss"
import { Clipboard } from '@capacitor/clipboard';


interface SubtitleEditorProps {
  subtitle: {
    s: string
    e: string
    t: string
  }
  isOpen: boolean
  onClose: () => void
  onSave: (editedSubtitle: { s: string; e: string; t: string }) => void
}

const SubtitleEditor: React.FC<SubtitleEditorProps> = ({
  subtitle,
  isOpen,
  onClose,
  onSave,
}) => {
  const [editedSubtitle, setEditedSubtitle] = useState(subtitle)
  const [copyButtonLabel, setCopyButtonLabel] = useState('Copy to Clipboard');


  // Use useEffect to update the state when the subtitle prop changes
  useEffect(() => {
    setEditedSubtitle(subtitle)
  }, [subtitle])

  const handleSave = (e) => {
    e.stopPropagation()

    onSave(editedSubtitle)
    onClose()
  }

  // const copyToClipboard = async () => {
  //   await Clipboard.write({
  //     string: editedSubtitle.t
  //   });
  // };
  const copyToClipboard = async () => {
    await Clipboard.write({
      string: editedSubtitle.t,
    });
    setCopyButtonLabel('Copied!');
  
    // Set a timeout to revert the label back to its original state
    setTimeout(() => {
      setCopyButtonLabel('Copy to Clipboard');
    }, 1500); // 1500 milliseconds = 1.5 seconds
  };
  
  
  return (
    <IonModal
      id="editSub-modal"
      isOpen={isOpen}
      onIonModalDidDismiss={onClose}
    >
      <IonHeader>
        <IonToolbar>
          <IonTitle>Edit Subtitle</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent className="editor-container">
        <IonLabel className="custom-time">
          {editedSubtitle.s} - {editedSubtitle.e}
        </IonLabel>

        <div className="subtitle-content">
          <IonTextarea
            rows={6}
            className="custom-textarea"
            value={editedSubtitle.t}
            // onKeyPress={(e) => {
            //   if (e.key === "Enter") e.preventDefault()
            // }}
            onIonChange={(e) => {
              // const newValue = e.detail.value!.replace(/[\r\n]+/g, " ") // Replace newlines and carriage returns with a space
              // setEditedSubtitle({ ...editedSubtitle, t: newValue })
              if (e.detail.value)
                setEditedSubtitle({ ...editedSubtitle, t: e.detail.value  })
            }}
            placeholder="Edit subtitle text"
          />
        </div>

        <div className="button-group">
          <IonButton fill="outline" size="small" onClick={copyToClipboard} slot="start">
            {copyButtonLabel}
          </IonButton>

          {/* Group for the other buttons on the right */}
          <div slot="end">



            <IonButton size="small" color="medium" onClick={() => {

  onClose();
}}>
              Cancel
            </IonButton>

            <IonButton size="small" onClick={(e) => handleSave(e)}>
              Save
            </IonButton>

          </div>
        </div>
      </IonContent>
    </IonModal>
  )
}

export default SubtitleEditor
