import React, { useRef, useState, useEffect } from "react"
import {
  IonContent,
  IonPopover,
  IonItem,
  IonLabel,
  IonIcon,
} from "@ionic/react"
import { caretDownCircleOutline } from "ionicons/icons"
import "./PlayerSubs.scss"
import {
  loadDefaultSimplifiedChineseParser,
  loadDefaultTraditionalChineseParser,
  loadDefaultJapaneseParser,
} from "budoux"
import pinyin from "pinyin";
import { Storage } from '@ionic/storage';

const hard_langs = ["ko", "hi", "zh", "id", "th", "vi", "ja"]

// Define the type of the subtitle object
type Subtitle = {
  s: string
  e: string
  t: string
}

interface PlayerSubsProps {
  languageMap: {}
  languageCode: string
  languageCodeOrig: string
  vttLoaded: Subtitle[]
  vttLoadedOrig: Subtitle[]
  activeSubtitleIndex: number
  activeSubtitleIndexOrig: number
  processingMsg: string
  addWordToRecents: (data: any) => any;
}

const PlayerSubs: React.FC<PlayerSubsProps> = ({
  languageMap,
  languageCode,
  languageCodeOrig,
  vttLoaded,
  vttLoadedOrig,
  activeSubtitleIndex,
  activeSubtitleIndexOrig,
  processingMsg,
  addWordToRecents
}) => {
  const accordionGroup = useRef<null | HTMLIonAccordionGroupElement>(null)


  //local transdict
  const storage = new Storage();
  storage.create();


  // Track the open state of each accordion
  //const [openAccordion, setOpenAccordion] = useState("");
  const [popoverContentOrig, setPopoverContentOrig] = useState("")
  const [popoverContent, setPopoverContent] = useState("")
  const [isContentOrigVisible, setContentOrigVisible] = useState(true)
  const [isContentVisible, setContentVisible] = useState(true)
  const [rotate, setRotate] = useState(false)
  const [rotateOrig, setRotateOrig] = useState(false)
  const chineseParser = loadDefaultSimplifiedChineseParser()
  const chineseParserTW = loadDefaultTraditionalChineseParser()
  const japaneseParser = loadDefaultJapaneseParser()
  const [allowPopover, setAllowPopover] = useState(true)

  const TRANSLATE1 = process.env.REACT_APP_API_TL1 || "";
  const TRANSLATE2 = process.env.REACT_APP_API_TL2 || "";


  //create storage key based on language code
  const getStorageKey = (languageCode) => `transdict_${languageCode}`;

const updateAllRelatedDictionaries = async (word, sourceL, translatedWord, targetL, sourceDict, targetDict) => {
  // Update source dictionary for the given word to include the new translation
  sourceDict[word] = sourceDict[word] || {};
  sourceDict[word][targetL] = translatedWord;

  // Update target dictionary for the translated word to include the source language
  targetDict[translatedWord] = targetDict[translatedWord] || {};
  targetDict[translatedWord][sourceL] = word;


  // // Include the newly added translation into the merged translations
  // mergedTranslations[targetL] = translatedWord;
    // Prepare a dictionary to hold all translations, merging existing ones with the new translation
    let mergedTranslations = {...sourceDict[word]}; // Start by copying all existing translations from the source

    // Now merge all translations found in the target dictionary for the translated word, ensuring all languages are captured
    if (targetDict[translatedWord]) {
        for (const [lang, transWord] of Object.entries(targetDict[translatedWord])) {
            // This ensures we're merging all associated translations from the target dictionary too
            mergedTranslations[lang] = transWord;
        }
    }

    // Also include the newly added translation into mergedTranslations
    mergedTranslations[targetL] = translatedWord;

  console.log("MERGED Translations >>>>>> :", mergedTranslations)


  // Iterate over each language present in the merged translations
  for (const lang in mergedTranslations) {
    
      const currentWord = mergedTranslations[lang]; // Retrieve the word corresponding to this language
      const dictKey = getStorageKey(lang); // Generate the dictionary storage key for this language
      const langDict = await storage.get(dictKey) || {}; // Retrieve or initialize the dictionary for this language
      

      // Ensure the dictionary entry for this word is initialized
      langDict[currentWord] = langDict[currentWord] || {};

        // Create a filtered version of mergedTranslations excluding the current language
        const filteredTranslations = {};
        for (const [innerLang, innerWord] of Object.entries(mergedTranslations)) {
            if (innerLang !== lang) {
                filteredTranslations[innerLang] = innerWord;
            }
        }
        // Set the dictionary for the current language's word to the filtered translations
        langDict[currentWord] = filteredTranslations;
console.log("Setting lang - word to:", lang, currentWord)
console.log(filteredTranslations)


      // Persist the updated dictionary back to storage
      await storage.set(dictKey, langDict);
  }

};


  useEffect(() => {
    if (languageCode === "zh-CN" || languageCodeOrig === "zh-CN") {
      setAllowPopover(false)
    } else {
      setAllowPopover(true)
    }
  }, [languageCode, languageCodeOrig])

  // Function to determine which parser to use based on language code
  const getParsedText = (text: string, language: string) => {
    if (language === "zh") {
      return chineseParser.parse(text)
    } else if (language === "zh-tw" || language === "zh-Hant") {
      return chineseParserTW.parse(text)
    } else if (language === "ja") {
      return japaneseParser.parse(text)
    } else {
      return text.split(" ")
    }
  }

  //subtitle lines under video
  const handleWordClick = async (word, sourceL, targetL) => {
    setPopoverContent("(translation in progress...)")
    setPopoverContentOrig("[" + word + "] :")
    let data
    let translatedText = ""
    // Check if either sourceL or targetL is in hard_langs
    console.log("[handleWordClick]: " + word)
    //Libretranslate
    if (sourceL !== targetL) {

      // First, attempt to retrieve the translation from Ionic Storage
      const sourceStorageKey = getStorageKey(sourceL);
      const targetStorageKey = getStorageKey(targetL);
      const sourceDict = await storage.get(sourceStorageKey) || {};
      const targetDict = await storage.get(targetStorageKey) || {};

      if (sourceDict[word] && sourceDict[word][targetL]) {
        // If translation is found in storage, use it directly
        translatedText = sourceDict[word][targetL];
        console.log("Translation retrieved from storage:", translatedText);
      } else {
        //No local stored translation get from api
        //No longer routing between hard and easy languages, just all send to api for now, commented is old code
        // if (hard_langs.includes(sourceL) || hard_langs.includes(targetL)) {
        //   console.log(">>>rapid")
        //   const res = await fetch(
        //     TRANSLATE1,
        //     {
        //       method: "POST",
        //       body: new URLSearchParams({
        //         from: sourceL,
        //         to: targetL,
        //         text: word,
        //       }),
        //     }
        //   )

        //   data = await res.json()
        //   translatedText = data && data.trans ? data.trans : word
        //   console.log("RAPID DL Translate called ", translatedText)
        // } else {
        //   //Route to LibreTranslate server
        //   console.log(">>>be")
        //   const res = await fetch(
        //     TRANSLATE2,
        //     {
        //       method: "POST",
        //       body: JSON.stringify({
        //         q: word,
        //         source: sourceL,
        //         target: targetL,
        //         format: "text",
        //         api_key: "", // Be sure to fill this in if necessary
        //       }),
        //       headers: { "Content-Type": "application/json" },
        //     }
        //   )
        //   data = await res.json()
        //   console.log("BE DL called ", data)
        //   translatedText =
        //     data && data.translatedText ? data.translatedText : word
        //   console.log("BE DL Translate called ", translatedText)
        // }

        //New single translate api only
        //TODO: add timeout for when backend freezes
        console.log(">>>rapid")
        const res = await fetch(
          TRANSLATE1,
          {
            method: "POST",
            body: new URLSearchParams({
              from: sourceL,
              to: targetL,
              text: word,
            }),
          }
        )

        data = await res.json()
        translatedText = data && data.trans ? data.trans : word
        console.log("RAPID DL Translate called ", translatedText)

        if (translatedText) {
          // Update storage with new translation
          await updateAllRelatedDictionaries(word, sourceL, translatedText, targetL, sourceDict, targetDict);

          // Call processAndAddWord instead of addToWords directly
          await addWordToRecents(
            {
            sourceL:sourceL,
            sourceText: word,
            sourceTile: vttLoadedOrig[activeSubtitleIndexOrig],
            targetL: targetL,
            targetText: translatedText,
            targetTile: vttLoaded[activeSubtitleIndex]
            }
          );
        }

      

      }
    }

    
    const trans = "[" + word + "] : \n" + translatedText
    //console.log("API called with word: ", word, sourceL, targetL, trans)
    setPopoverContent(translatedText)
  }

  const processingMessage =
    "(subtitle processing... please check back in a few minutes)"

  //Origin/Native text color: style={{ color: "#85929E" }}

  return (
    <>
      <IonItem className="locked-label">
        <IonLabel

          className={`custom-label ${vttLoadedOrig.length === 0 ? "gradient-text" : ""}`}

          style={{ color: "rgb(71,143,252)" }}
          position="stacked"
        >
          <IonIcon
            icon={caretDownCircleOutline}
            onClick={() => {
              setContentOrigVisible(!isContentOrigVisible)
              setRotateOrig(!rotateOrig)
            }}
            style={{
              cursor: "pointer",
              marginRight: "0.33rem",
              transition: "transform 0.5s",
              transform: rotateOrig ? "rotate(-90deg)" : "",
            }}
            color="danger"
            size="small"
          />
          {vttLoadedOrig.length === 0
            ? processingMsg
            : "Original/Native: " + (languageMap[languageCodeOrig] || languageCodeOrig)}
        </IonLabel>
        <div
          className="custom-div"
          style={{
            display: isContentOrigVisible ? "block" : "none",
            width: "100%",
          }}
        >
          <div className="subcontent-top" id="click-trigger-orig">
          {(getParsedText(vttLoadedOrig[activeSubtitleIndexOrig]?.t || "", languageCodeOrig) || [])
              .map((word, i) => 
                  <span
                    key={i} // Combine both indices for a unique key
                    onClick={() =>
                      (languageCodeOrig !== "zh-CN" && languageCode !== "zh-CN") ?
                        handleWordClick(word, languageCodeOrig, languageCode) : null
                    }
                    style={{
                      cursor: "pointer",
                      marginRight: "4.5px",
                      lineHeight: "1.4rem",
                      fontSize: "1.2rem",
                    }}
                  >
                    {word}
                  </span>
                
              )
          }

            {allowPopover && (
              <IonPopover
                trigger="click-trigger-orig"
                triggerAction="click"
                side="top"
              >
                <IonContent color="dark" class="ion-padding">
                  <IonLabel className="popoverOrig-label" color="primary">
                    {popoverContentOrig}
                  </IonLabel>
                  <br />
                  <IonLabel className="popover-label" color="light">
                    {popoverContent}
                  </IonLabel>
                </IonContent>
              </IonPopover>
            )}
          </div>
        </div>
      </IonItem>

      <IonItem className="locked-label">
        <IonLabel
          className={`custom-label ${vttLoaded.length === 0 ? "gradient-text-lower" : ""}`}
          style={{ color: "#D4AC0D" }}
          position="stacked"
        >
          <IonIcon
            icon={caretDownCircleOutline}
            onClick={() => {
              setContentVisible(!isContentVisible)
              setRotate(!rotate)
            }}
            style={{
              cursor: "pointer",
              marginRight: "0.33rem",
              transition: "transform 0.5s",
              transform: rotate ? "rotate(-90deg)" : "",
            }}
            color="danger"
            size="small"
          />
          {vttLoaded.length === 0
            ? processingMsg
            : "Selected: " + (languageMap[languageCode] || languageCode)}
        </IonLabel>

        <div
          className="custom-div"
          style={{
            display: isContentVisible ? "block" : "none",
            width: "100%",
          }}
        >
          <div
            className="subcontent-top"
            id="click-trigger-selected"
            style={{
              color: "#ffce00",
              borderRadius: "1px",
              padding: "3px",
              whiteSpace: "pre-wrap",
              overflowWrap: "break-word",
              border: "none",
              maxHeight: "3.4rem",
              overflow: "auto",
            }}
          >
            {(getParsedText(vttLoaded[activeSubtitleIndex]?.t || "", languageCode) || "")
              .map((word, i) => (
                <span
                  key={i}
                  onClick={() =>
                    (languageCode !== "zh-CN" && languageCodeOrig !== "zh-CN") ?
                      handleWordClick(word, languageCode, languageCodeOrig) : null
                  }
                  style={{
                    cursor: "pointer",
                    marginRight: "4.5px",
                    fontSize: "1.2rem",
                    lineHeight: "1.4rem",
                  }}
                >
                  {word}
                </span>
              ))}
            {allowPopover && <IonPopover
              trigger="click-trigger-selected"
              triggerAction="click"
              side="bottom"
            >
              <IonContent color="dark" class="ion-padding">
                <IonLabel className="popoverOrig-label" color="primary">
                  {popoverContentOrig}
                </IonLabel>
                <br />
                <IonLabel className="popover-label" color="light">
                  {popoverContent}
                </IonLabel>
              </IonContent>
            </IonPopover>}
          </div>
        </div>
      </IonItem>
    </>
  )
}

export default PlayerSubs